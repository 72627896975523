import React, { useState, useEffect } from "react";
import "./responsive.css";
import axios from "axios";
import { BASE_URL } from "../../../env";

const VehicleInformation = ({
  cibilScore,
  onPrevious,
  onNext,
  handleMaxPriceChange,
  userId,
  handleVehicleInfo
}) => {
  const [manufacturingYear, setManufacturingYear] = useState([]);
  const [selectedYear, setSelectedYear] = useState("");
  const [brands, setBrands] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState("");
  const [models, setModels] = useState([]);
  const [selectedModel, setSelectedModel] = useState("");
  const [variants, setVariants] = useState([]);
  const [selectedVariant, setSelectedVariant] = useState("");
  const [selectedOwner, setSelectedOwner] = useState("");
  const [kilometersDriven, setKilometersDriven] = useState("");
  const [maxPrice, setMaxPrice] = useState("");
  const [minPrice, setMinPrice] = useState("");
  const [result, setResult] = useState(0);
  const [loading, setLoading] = useState(false);


  const ManufacturingYear = () => {
    const currentYear = new Date().getFullYear();
    const lastTenYears = [];

    for (let year = currentYear; year >= currentYear - 15; year--) {
      lastTenYears.push(year);
    }
    setManufacturingYear(lastTenYears);
  };

  useEffect(() => {
    ManufacturingYear();
  }, []);
  // year Change
  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };
  useEffect(() => {
    axios
      .get(`${BASE_URL}/brands`)
      .then((response) => {
        setBrands(response.data);

        // console.log(response.data)
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  //  car model api
  const handleBrandChange = (event) => {
    const brandId = event.target.value;

    setSelectedBrand(parseInt(brandId));

    axios
      .get(`${BASE_URL}/models/${brandId}`)
      .then((response) => {
        setModels(response.data);
        setSelectedModel("");
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // model change
  const handleModelChange = (event) => {
    const modelId = event.target.value;
    setSelectedModel(parseInt(modelId));

    axios
      .get(`${BASE_URL}/variants/${modelId}`)
      .then((response) => {
        setVariants(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //  handle varint change
  const handleVariantChange = (event) => {
    const variantId = event.target.value;
    setSelectedVariant(parseInt(variantId));
  };
  const handleOwnerChange = (event) => {
    setSelectedOwner(event.target.value);
  };

  const validatedPrice = async (e) => {
    e.preventDefault();
    if (selectedYear && selectedOwner && selectedVariant) {
      const selectedVariantObj = variants.find(
        (variant) => variant.variant_id === parseInt(selectedVariant)
      );
  
      if (selectedVariantObj) {
        const adjusted = calculateAdjustedPrice(
          selectedVariantObj.price,
          parseInt(selectedYear),
          selectedOwner
        );
  
       
  
        const currentYear = new Date().getFullYear();
        const yearsSinceManufacturing = currentYear - parseInt(selectedYear);
  
        let newMaxPrice;
  
        if (yearsSinceManufacturing < 3) {
          newMaxPrice = adjusted + 25000;
        } else if (yearsSinceManufacturing >= 3) {
          newMaxPrice = adjusted + 25000;
        }
  
        setMaxPrice(newMaxPrice);
        setMinPrice(adjusted);
  
      
        calculateLoanAmount(newMaxPrice);
      }
    }
  };
  
  
  const calculateLoanAmount = (newMaxPrice) => {
    setLoading(true);
    try {
      const selectedBrandObj = brands.find(
        (brand) => brand.brand_id === selectedBrand
      );
      const selectedModelObj = models.find(
        (model) => model.model_id === selectedModel
      );
      const selectedVariantObj = variants.find(
        (variant) => variant.variant_id === selectedVariant
      );
  
      // posting vehicle_information
      const formData = {
        user_id: userId,
        selectedYear: selectedYear,
        selectedBrand: selectedBrandObj.brand_name,
        selectedModel: selectedModelObj.model_name,
        selectedVariant: selectedVariantObj.variant_name,
        kilometersDriven: kilometersDriven,
        ownerType: selectedOwner,
        maxprice:newMaxPrice, 
        minprice: minPrice,
      };
  
      axios
        .post(`${BASE_URL}/loanform/user/vehicleinformation`, formData)
        .then((res) => {
         
          onNext(selectedYear,newMaxPrice);
        })
        .catch((err) => {
          console.log(err);
        });
      setResult(result);

      // for checking
      handleVehicleInfo(formData.selectedBrand,formData.selectedModel,formData.selectedVariant)
      onNext(selectedYear,newMaxPrice);
      
    } catch (err) {
      console.log("Error in FormSubmission", err);
    }finally {
      setLoading(false);
    }
  };


  const calculateAdjustedPrice = (baseprice, selectedYear, ownerType) => {
    const currentYear = new Date().getFullYear();
    let adjustedPrice = baseprice;
    for (let year = selectedYear; year <= currentYear; year++) {
      const yearsSinceManufacturing = year - selectedYear;
      let priceReductionPercentage;

      if (yearsSinceManufacturing <= 8) {
        if(adjustedPrice > 1000000){
          priceReductionPercentage = 0.10;
        }else{
          priceReductionPercentage = 0.05;
        }
      } else {
        priceReductionPercentage = 0.025;
      }

      adjustedPrice -= adjustedPrice * priceReductionPercentage;
    }
    if (ownerType === "first") {
      adjustedPrice -= adjustedPrice * 0.05;
    } else if (ownerType === "second") {
      adjustedPrice -= adjustedPrice * 0.1;
    } else if (ownerType === "third") {
      adjustedPrice -= adjustedPrice * 0.15;
    }

    return adjustedPrice;
  };



  return (
    <div className="container  formbg step-form ">
     
{/* <p>{cibilScore}</p> */}
      <form>
        <div className="form-floating">
          <select
            className="form-select"
            id="floatingSelect"
            aria-label="Floating label select example"
            value={selectedYear}
            onChange={handleYearChange}
          >
            <option selected value="">
              select year
            </option>
            {manufacturingYear.map((year, i) => (
              <option key={i} value={year}>
                {year}
              </option>
            ))}
          </select>
          <label for="floatingSelect">Manufacturing year</label>
        </div>
        {/* car brand */}

        <div className="form-floating mt-4">
          <select
            className="form-select"
            id="floatingSelect"
            aria-label="Floating label select example"
            value={selectedBrand}
            onChange={handleBrandChange}
          >
            <option value="">Select your car Brand</option>
            {brands.map((brand) => (
              <option key={brand.brand_id} value={brand.brand_id}>
                {brand.brand_name}
              </option>
            ))}
          </select>
          <label for="floatingSelect">Select Brand</label>
        </div>
        {/* car model */}
        <div className="form-floating">
          <select
            className="form-select"
            id="floatingSelect"
            aria-label="Floating label select example"
            value={selectedModel}
            onChange={handleModelChange}
          >
            <option selected value="">
              Select Model
            </option>
            {models.map((model) => (
              <option key={model.model_id} value={model.model_id}>
                {model.model_name}
              </option>
            ))}
          </select>
          <label for="floatingSelect">Select Model</label>
        </div>
        {/* car variant */}

        <div className="form-floating">
          <select
            className="form-select"
            id="floatingSelect"
            aria-label="Floating label select example"
            value={selectedVariant}
            onChange={handleVariantChange}
          >
            <option selected value="">
              Select variant
            </option>
            {variants.map((variant) => (
              <option key={variant.variant_id} value={variant.variant_id}>
                {variant.variant_name}
              </option>
            ))}
          </select>
          <label for="floatingSelect">Select variant</label>
        </div>
        {/* kilometers driven */}
        <div className="form-floating">
          <input
            type="number"
            className="form-control kilometer"
            id="kilometer"
            onChange={(e) => setKilometersDriven(e.target.value)}
          />
          <label for="kilometer">kilometeres Driven</label>
        </div>

        {/* owner type */}

        <div className="form-floating mt-3">
          <div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                name="inlineRadioOptions"
                id="inlineRadio1"
                type="radio"
                value="first"
                checked={selectedOwner === "first"}
                onChange={handleOwnerChange}
              />
              <label class="form-check-label" for="inlineRadio1">
                First Owner
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                name="inlineRadioOptions"
                id="inlineRadio2"
                type="radio"
                value="second"
                checked={selectedOwner === "second"}
                onChange={handleOwnerChange}
              />
              <label class="form-check-label" for="inlineRadio2">
                second owner
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                name="inlineRadioOptions"
                id="inlineRadio3"
                type="radio"
                value="third"
                placeholder="Kilometers Driven"
                checked={selectedOwner === "third"}
                onChange={handleOwnerChange}
              />
              <label class="form-check-label" for="inlineRadio3">
                Third owner
              </label>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center mt-3">
          {/* <button
            className="btn btn-secondary text-white next-btn mx-2 "
            onClick={onPrevious}
          >
            Back
          </button> */}
          <button
            type="submit"
            className="btn btn-warning text-white w-50  mx-2 mb-3  px-4"
            onClick={(e) => {
              validatedPrice(e);
              // calculateLoanAmount()
            }}
            disabled={loading}
          >
             {loading ? "Submitting..." : "Next"}
          
          </button>

       
        </div>

        {/* {adjustedPrice > 0 && (
          <div className="slider ">
            <div className=" mt-4  col-12 d-flex justify-content-between range-slider">
              <label htmlFor="min-price">
                {" "}
                Fair Condition
                <br />
                {minPrice.toFixed(0)}
              </label>
              <label htmlFor="max-price">
                {" "}
                Good Condition
                <br />
                {maxPrice.toFixed(0)}
              </label>
            </div>
         
          </div>
        )} */}
      </form>
    </div>
  );
};

export default VehicleInformation;
