import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import "../datatable/datatable.css";
import axios from "axios";
import { BASE_URL } from "../../../env";
import NavigationHeader from "../NavigationHeader";
const LoanFollowUpList = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
    axios
      .get(`${BASE_URL}/loanapplication/all/followups`)
      .then((res) => {
        const updatedData = res.data.map((row, index) => ({
          ...row,
          id: index + 1,
        }));
        setData(updatedData);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const followups = [
    { field: "id", headerName: "ID", Width: 70 },
    {
      field: "followupDate",
      headerName: "Followup Date",
      width: 150,
      renderCell: (params) => {
        const date = new Date(params.row.followupDate);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");

        const formattedDate = `${day}-${month}-${year}`;

        return <div>{formattedDate}</div>;
      },
    },
    { field: "followComment", headerName: "Follow Comment", width: 300 },
    { field: "first_name", headerName: "First Name", width: 300 },
    { field: "last_name", headerName: "Last Name", width: 300 },
    { field: "phone_number", headerName: "Phone Number", width: 300 },
    { field: "cibilScore", headerName: "Cibil", width: 300 },
    { field: "loanAmount", headerName: "Loan Amount", width: 300, 
      valueFormatter: (params) => {
        const value = params.value;
        return value ? parseFloat(value).toFixed(2) : '';
      } 
    },
  ];

  return (
    <div className="datatable">
      <NavigationHeader/>
      <div className="datatableTitle">
        <DataGrid
          className="datagrid"
          columns={followups}
          rows={data}
          pageSize={9}
          rowsPerPageOptions={[9]}
        />
      </div>
    </div>
  );
};

export default LoanFollowUpList;
