import React, { useState, useEffect } from "react";
import axios from "axios";
import { BASE_URL } from "../../../env";
import { DataGrid } from "@mui/x-data-grid";
import "../datatable/datatable.css";
import NavigationHeader from "../NavigationHeader";

const LoanCompletionList = () => {
  const [Data, setData] = useState([]);

  useEffect(() => {
    fetchnotinterested();
  }, []);

  const fetchnotinterested = () => {
    

    axios
      .get(`${BASE_URL}/loan/application/all/completedlist`)
      .then((res) => {
     
        const updatedData = res.data.map((row, index) => ({
          ...row,
          id: index + 1,
        }));
        setData(updatedData);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const notinterested = [
    { field: "id", headerName: "ID", Width: 200 },
    { field: "first_name", headerName: "First Name", width: 300 },
    { field: "last_name", headerName: "Last Name", width: 300 },
    { field: "phone_number", headerName: "Phone Number", width: 300 },
    { field: "cibilScore", headerName: "Cibil", width: 300 },
    { field: "loanAmount", headerName: "LoanAmount", width: 300 },
  ];
  return (
    <div className="datatable">
      <NavigationHeader/>
      <div className="datatableTitle">
        <DataGrid
          className="datagrid"
          columns={notinterested}
          rows={Data}
          pageSize={9}
          rowsPerPageOptions={[9]}
        />
      </div>
    </div>
  );
};

export default LoanCompletionList;
