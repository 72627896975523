import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import "../datatable/datatable.css";
import axios from "axios";
import { BASE_URL } from "../../../env";
import NavigationHeader from "../NavigationHeader";
const DealerregisteredList = () => {
  const [dealerData, setDealerData] = useState([]);
  useEffect(() => {
    axios
      .get(`${BASE_URL}/admindealerdetails`)
      .then((res) => {
      const updatedData =res.data.map((row,index)=>({
        ...row,
        id:index+1
      }))
        // console.log(res.data);
        setDealerData(updatedData);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleDelete = (id) => {
    console.log(id);
  };

  const dealercolumns = [
    { field: "id", headerName: "ID", Width: 200 },
    { field: "firstName", headerName: "First Name", width: 400 },
    { field: "lastName", headerName: "Last Name", width: 400 },
    { field: "mobile", headerName: "phoneNumber", width: 400 },
    
    // {
    //   field: "actions",
    //   headerName: "Actions",
    //   width: 100,
    //   renderCell: (params) => (
    //     <button
    //       className="btn btn-danger"
    //       onClick={() => handleDelete(params.row.id)}
    //     >
    //       Delete
    //     </button>
    //   ),
    // },
  ];

  return (
    <div className="datatable">
      <NavigationHeader/>
      <div className="datatableTitle">
        <DataGrid
          className="datagrid"
          columns={dealercolumns}
          rows={dealerData}
          pageSize={9}
          rowsPerPageOptions={[9]}
        />
      </div>
    </div>
  );
};

export default DealerregisteredList;
