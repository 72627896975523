import React, { useState } from "react";
import "./Calculator.css";
import { Chart as ChartJS, ArcElement, Legend, Tooltip } from "chart.js/auto";
import { Pie } from "react-chartjs-2";
ChartJS.register(ArcElement, Legend, Tooltip);
//interest total

function calculateEMI(principal, rate, tenure) {
  const monthlyInterest = rate / 12 / 100;
  const denominator = Math.pow(1 + monthlyInterest, tenure) - 1;
  const emi =
    ((principal * monthlyInterest) / denominator) *
    Math.pow(1 + monthlyInterest, tenure);

  return emi.toFixed(0);
}

function Calculator() {
  const [principal, setPrincipal] = useState("100000");
  const [rate, setRate] = useState("8");
  const [tenure, setTenure] = useState("8");
  const [emiList, setEmiList] = useState([]);
  const [monthlyEmi, setMonhlyEmi] = useState(0);
  // total result

  //   result values
  const [totalAmount, setTotalAmount] = useState(100000);
  const [resultInterest, setResultInterest] = useState(8);
  const [totalinterest, setTotalInterest] = useState(8);

  const handleCalculate = () => {
    const emis = [];
    const monthlyEMI = calculateEMI(principal, rate, tenure);
    setMonhlyEmi(monthlyEMI);
    let remainingPrincipal = principal;

    for (let i = 1; i <= tenure; i++) {
      const interestPayment = (remainingPrincipal * (rate / 12)) / 100;
      const principalPayment = monthlyEMI - interestPayment;
      remainingPrincipal -= principalPayment;
      //  console.log( monthlyEMI )
      emis.push({
        month: i,
        emi: monthlyEMI,
        principalPayment,
        interestPayment,
        remainingPrincipal,
      });
    }

    setEmiList(emis);

    let p = parseFloat(principal);
    let r = parseFloat(rate);
    let n = parseFloat(tenure);

    const actualrate = parseFloat(r / 12 / 100);

    let calceemi =
      p *
      actualrate *
      (Math.pow(1 + actualrate, n) / (Math.pow(1 + actualrate, n) - 1));

    setResultInterest(Math.round(calceemi));
    setTotalAmount(Math.round(calceemi * n));
    setTotalInterest(Math.round(calceemi * n - p));
  };

  // chart
  const data = {
    labels: [" Loan Amount", "Total Interest"],
    datasets: [
      {
        label: "Total",
        data: [totalAmount, totalinterest],
        backgroundColor: ["#4d4dff", "#a6bcff"],
      },
    ],
  };

  return (
    <>
      <div className="container mt-4 py-5  shadow gap-3 " id="CalculatorSession">
        <h5 className="section-header">EMI CALCULATOR</h5>
        <div className="row d-flex justify-content-between">
          <div className="col-sm-6 col-md-6 col-xl-6 left-calci  px-5 ">
            <div>
              <label className="calci-label">Loan Amount :</label>
              <br />
              <input
                type="number"
                min="100000"
                max="1000000"
                step="50000"
                onChange={(e) => setPrincipal(e.target.value)}
              />

              {/* <h4>{principal}</h4> */}
            </div>
            <div>
              <label  className="calci-label">Interest Rate (%): </label>
              <input
                type="number"
                min="8"
                max="22"
                step="1"
                onChange={(e) => setRate(e.target.value)}
              />
              {/* <h4>{rate}%</h4> */}
            </div>
            <div>
              <label  className="calci-label">Loan Tenure (Months): </label>
              <input
                type="number"
                min="8"
                max="84"
                step="1"
                onChange={(e) => setTenure(e.target.value)}
              />
              {/* <h4>{tenure}</h4> */}
            </div>
            <button className="btn calculateemi" onClick={handleCalculate}>
              Calculate
            </button>
          </div>

          <div className="col-sm-6 col-md-6 col-xl-6 right-calci  p-5">
            <div style={{ height: "300px", width: "400px" }}>
              {/* <Pie data={data}/> */}

              <div className="resultcard pt-2">
                <div className="resultemi">
                <p>Monthly Emi : {monthlyEmi}</p>
                </div>
               <div className="resultemi mt-3">
               <p>Total Amount :{totalAmount} </p>
               </div>
               <div className="resultemi mt-3">
                 <p>Total Interst :{totalinterest}</p>
               </div>
               
              </div>
            </div>
          </div>
          <div className=" container mt-4 col-sm-12 col-md-12 col-xl-12">
        <table className="col-sm-12 col-md-12 col-xl-12 table table-bordered table-responsive">
          <thead className="table-header">
            <tr className="tablehead-row">
              <th scope="col">Month</th>
              <th scope="col">EMI</th>
              <th scope="col">Principal Payment</th>
              <th scope="col">Interest Payment</th>
              <th scope="col">Remaining Principal</th>
            </tr>
          </thead>
          <tbody>
            {emiList.map((emi) => (
              <tr key={emi.month} style={{ padding: "10px" }}>
                <td style={{ padding: "10px" }}>
                  {Number(emi.month).toFixed(0)}
                </td>
                <td style={{ padding: "10px" }}>
                  {Number(emi.emi).toFixed(0)}
                </td>
                <td style={{ padding: "10px" }}>
                  {Number(emi.principalPayment).toFixed(0)}
                </td>
                <td style={{ padding: "10px" }}>
                  {Number(emi.interestPayment).toFixed(0)}
                </td>
                <td style={{ padding: "10px" }}>
                  {Number(emi.remainingPrincipal).toFixed(0)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
        </div>
        
      </div>
      
    </>
  );
}

export default Calculator;
