
import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import "../../components/datatable/datatable.css"
import axios from "axios";
import { BASE_URL } from "../../../env";
import NavigationHeader from "../../components/NavigationHeader";
const  LoanFollowUp = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
    axios
      .get(`${BASE_URL}/all/followuplist`)
      .then((res) => {
      const updatedData =res.data.map((row,index)=>({
        ...row,
        id:index+1
      }))
        console.log(res.data);
        setData(updatedData);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

 
  const followups = [
    { field: "id", headerName: "ID", Width: 70 },
    { field: "followupDate", headerName: "Followup Date", width: 150 ,  renderCell: (params) => {
      const date = new Date(params.row.followupDate);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");

      const formattedDate = `${day}-${month}-${year}`;

      return <div>{formattedDate}</div>;
    },
   },
    { field: "followComment", headerName: "Follow Comment", width: 300 },
    { field: "phoneNumber", headerName: "phoneNumber", width: 300 },
    { field: "selectedBrand", headerName: "SelectedBrand", width: 300 },
    { field: "selectedModel", headerName: "SelectedModel", width: 300 },
    { field: "selectedVariant", headerName: "SelectedVariant", width: 300 },
    
  
  ];

  return (
    <div className="datatable">
      <NavigationHeader/>
      <div className="datatableTitle">
        <DataGrid
          className="datagrid"
          columns={followups}
          rows={data}
          pageSize={9}
          rowsPerPageOptions={[9]}
         
        />
      </div>
    </div>
  );
};

export default  LoanFollowUp;
