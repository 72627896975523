import React, { useRef } from "react";
import html2pdf from "html2pdf.js";
import CibilDetail from "../../table/cibilDetail/CibilDetail";
import Lottie from "react-lottie";
import nodateanimation from "../../../../asserts/nodata.json";

const CibilProfile = ({ isOpen, onRequestClose, selectedRow }) => {
  const contentRef = useRef(null);
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: nodateanimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  // Safely access cibilScore and data from selectedRow
  const cibilScore = selectedRow?.cibilScore ?? null;
  const data = selectedRow?.data ?? null;

  const downloadAsPDF = () => {
    const content = contentRef.current;

    if (content) {
      const pdfOptions = {
        margin: 10,
        filename: "cibil_detail.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
      };

      html2pdf(content, pdfOptions);
    }
  };

  return (
    <div className="cibil-content">
      <div
        className={`modal ${isOpen ? "show" : ""}`}
        tabIndex="-1"
        role="dialog"
        style={{ display: isOpen ? "block" : "none" }}
      >
        <div className="modal-dialog modal-fullscreen">
          <div className="modal-content">
            {/* Show Lottie animation if cibilScore is "0", data is null, or selectedRow is null */}
            {cibilScore === "0" || data === null || selectedRow === null ? (
              <div
                className="modal-body"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Lottie options={defaultOptions} height={300} width={300} />
                <button className="btn btn-danger" onClick={onRequestClose}>
                  Back to Dashboard
                </button>
              </div>
            ) : (
              <>
                <div className="modal-header">
                
                  <button
                    type="button"
                    className="btn-close"
                    onClick={onRequestClose}
                  ></button>
                </div>
                <div className="modal-body" ref={contentRef}>
                  <CibilDetail selectedRow={selectedRow} />
                </div>
              </>
            )}

            <div className="modal-footer"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CibilProfile;
