import React, { useState } from "react";
import "./loanform.css";

const LoanForm = () => {
  const [currentStep, setCurrenStep] = useState(1);

  const nextStep = () => {
    setCurrenStep((prevStep) => prevStep + 1);
  };

  const prevStep = () => {
    setCurrenStep((prevStep) => prevStep - 1);
  };

  const handleSubmit = () => {
    console.log("your form submitted");
  };
  return (
    <div className="applyloan">
      <div className="form-loan ">
        <header style={{ color: "white" }}>APPLY LOAN FORM</header>
        <div className="progress-bar">
          {["User Information", "Vehicle Information", "Cibile-profile"].map(
            (step, index) => (
              <div className="step" key={index}>
                <p className={currentStep === index + 1 ? "active" : ""}>
                  {step}
                </p>
                <div className="bullet">
                  <span className={currentStep === index + 1 ? "active" : ""}>
                    {index + 1}
                  </span>
                </div>
                <div
                  className={`check fas fa-check ${
                    currentStep > index + 1 ? "active" : ""
                  }`}
                ></div>
              </div>
            )
          )}
        </div>

        <div className="form-outer">
          <form action="#">
            <div className={`page${currentStep === 1 ? "active" : ""}`}>
              <div className="field">
                <div className="label">First Name</div>
                <input type="text" />
              </div>
              <div className="field">
                <div className="label">Last Name</div>
                <input type="text" />
              </div>

              <div className="field">
                <div className="label">Email</div>
                <input type="text" />
              </div>
              <div className="field">
                <div className="label">Phone</div>
                <input type="text" />
              </div>

              <div className="field">
                <button className="firstNext  next" onClick={nextStep}>
                  Next
                </button>
              </div>
            </div>

            {/* 2nd page */}
            <div className={`page${currentStep === 2 ? "active" : ""}`}>
              <div className="field">
                <div className="label">First Name</div>
                <input type="text" />
              </div>
              <div className="field">
                <div className="label">Last Name</div>
                <input type="text" />
              </div>

              <div className="field">
                <div className="label">Email</div>
                <input type="text" />
              </div>
              <div className="field">
                <div className="label">Phone</div>
                <input type="text" />
              </div>

              <div className="field">
                <button className="prev-3 prev" onClick={prevStep}>
                  Previous
                </button>
                <button className="firstNext  next" onClick={nextStep}>
                  Next
                </button>
              </div>
            </div>
            <div className={`page${currentStep === 3 ? "active" : ""}`}>
              <div className="field">
                <div className="label">First Name</div>
                <input type="text" />
              </div>
              <div className="field">
                <div className="label">Last Name</div>
                <input type="text" />
              </div>

              <div className="field">
                <div className="label">Email</div>
                <input type="text" />
              </div>
              <div className="field">
                <div className="label">Phone</div>
                <input type="text" />
              </div>

              <div className="field">
                <button className="prev-3 prev" onClick={prevStep}>
                  Previous
                </button>
                <button className="firstNext  next" onClick={nextStep}>
                  Next
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default LoanForm;
