import React from 'react';
import { Modal } from 'react-bootstrap';
import tick from "../../asserts/formsimg/tick.json";
import Lottie from "lottie-react";


export default function SuccessModal({ visible, message, onRequestClose }) {
  return (
    <Modal centered show={visible} onHide={onRequestClose}>
      <Modal.Body className="mx-auto text-center">
        <Lottie className="mx-auto otp-icon" animationData={tick} /> 
        <p className="otpmessage">{message}</p>
     
      </Modal.Body>
    </Modal>
  );
}
