import React, { useEffect, useState } from "react";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";
import { BASE_URL } from "../../../env";
import "../../components/datatable/datatable.css";
import "./Modal.css";

const CarModelTable = () => {
  const [data, setData] = useState([]);
  const [inputValues, setInputValues] = useState({
    brand_name: "",
    model_name: "",
    variant_name: "",
    price: 0,
  });
  const [selectedRow, setSelectedRow] = useState(null);

  const fetchData = () => {
    axios
      .get(`${BASE_URL}/allcarmodels`)
      .then((res) => {
        const updatedData = res.data.map((row, index) => ({
          ...row,
          id: index + 1,
        }));
        setData(updatedData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);
  const tableColumns = [
    { field: "id", headerName: "ID", width: 100 ,headerClassName:"custom-table-header"},
    { field: "brand_name", headerName: "Brand", width: 350 ,headerClassName:"custom-table-header"},
    { field: "model_name", headerName: "Models", width: 250 ,headerClassName:"custom-table-header"},
    { field: "variant_name", headerName: "Variants", width: 250,headerClassName:"custom-table-header" },
    { field: "price", headerName: "Price", width: 250,headerClassName:"custom-table-header" },
    {
      field: "actions",
      headerName: "Actions",
      width: 350,
      headerClassName:"custom-table-header",
      renderCell: (params) => (
        <>
          <span
            className="btn btn-warning"
            type="button"
            data-bs-toggle="modal"
            data-bs-target="#editModal"
            onClick={() => handleEdit(params.row)}
          >
            Edit
          </span>

          {params.row.is_active === 0 ? (
            <span
              className="btn btn-success"
              onClick={() => handletoggleactive(params.row.variant_id)}
              style={{ marginLeft: "1rem" }}
            >
              Activate
            </span>
          ) : (
            <span
              className="btn btn-danger"
              onClick={() => handletoggleactive(params.row.variant_id)}
              style={{ marginLeft: "1rem" }}
            >
              Deactivate
            </span>
          )}
        </>
      ),
    },
  ];

  const handleEdit = (row) => {
    setSelectedRow(row);
    setInputValues({
      brand_name: row.brand_name,
      model_name: row.model_name,
      variant_name: row.variant_name,
      price: row.price,
    });
  };

  const handleInput = (event, row) => {
    const { name, value } = event.target;

    setInputValues((prevInputValues) => ({
      ...prevInputValues,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    axios
      .put(
        `${BASE_URL}/updatecarmodel/${selectedRow.variant_name}`,
        inputValues
      )
      .then((res) => {
        

        fetchData();

        setInputValues({
          brand_name: "",
          model_name: "",
          variant_name: "",
          price: 0,
        });
        setSelectedRow(null);
      })
      .catch((err) => console.log(err));
  };

  const handletoggleactive = (variant_id) => {
    axios
      .put(`${BASE_URL}/variants/${variant_id}/toggle_active`)
      .then((res) => {
      
        fetchData();
      })
      .catch((err) => {
        console.log(err);
      });

  };
  return (
    <div>
      <div className="datatable">
        <div className="datatableTitle">
          <DataGrid
            className="datagrid"
            columns={tableColumns}
            rows={data}
            pageSize={10}
            rowsPerPageOptions={[10]}
            autoWidth
            style={{ width: "100%" }}
          />
        </div>
      </div>
      <div
        className="modal fade"
        tabIndex="-1"
        id="editModal"
        aria-labelledby="editModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h6 className="modal-title" id="editModalLabel">
                Edit
              </h6>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <input
                type="text"
                placeholder="Brand Name"
                name="brand_name"
                value={inputValues.brand_name}
                onChange={(event) => handleInput(event, selectedRow)}
              ></input>

              <input
                type="text"
                placeholder="Model Name"
                name="model_name"
                value={inputValues.model_name}
                onChange={(event) => handleInput(event, selectedRow)}
              ></input>
              <input
                type="text"
                placeholder="Variants Name"
                name="variant_name"
                value={inputValues.variant_name}
                onChange={(event) => handleInput(event, selectedRow)}
              ></input>
              <input
                type="number"
                placeholder="Price"
                name="price"
                value={inputValues.price}
                onChange={(event) => handleInput(event, selectedRow)}
              ></input>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>

              <button
                type="button"
                className="btn btn-primary"
                onClick={handleSubmit}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CarModelTable;
