import React from "react";
import "./contact.css";

const Contact = () => {
  return (
    <div className="container contact-form" id="ContactSession">
      <section id="formHolder">
        <div className="row">
          <div className="col-sm-6 brand">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7849.344482808404!2d77.975286!3d10.36807!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b00ab4ba0a67f8f%3A0xe8c929fa6a6e6e95!2sVirtual%20Technologies!5e0!3m2!1sen!2sin!4v1695203384577!5m2!1sen!2sin"
              height="450"
              style={{
                border: "0",
                maxWidth: "1080px",
                width: "90%",
                margin: "auto",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",

                marginTop: "1rem",
              }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              title="Virtual Technology"
            ></iframe>
          </div>

          <div className="col-sm-6 form">
            <div className="signup form-peice">
              <form class="signup-form" action="#" method="post">
                <h4 className="contact-heading">Contact us</h4>
                <div className="form-group">
                  <label htmlFor="name">Full Name</label>
                  <input
                    type="text"
                    name="username"
                    id="name"
                    className="name"
                  />
                  <span className="error"></span>
                </div>

                <div className="form-group">
                  <label htmlFor="phone">Email Adderss</label>
                  <input
                    type="text"
                    name="phone"
                    id="email"
                    className="phone"
                  />
                  <span className="error"></span>
                </div>

                <div className="form-group">
                  <label htmlFor="message">Message </label>
                  <input type="text" name="message" id="message" />
                </div>

                <div className="CTA">
                  <input type="submit" value="Contact Now" id="submit" />
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Contact;
