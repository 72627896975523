export const adminValidation = (inputValues) => {
 
    let error = {};
    if (inputValues.firstName === "") {
      error.firstName = "Enter Your Name";
    }
  
    if (inputValues.lastName === "") {
      error.lastName = "Enter your lastname";
    }
    if (inputValues.mobile === "") {
      error.mobile = "Enter your Mobile Number";
    } else if (!/^\d{10}$/.test(inputValues.mobile)) {
      error.mobile = "Mobile Number should be exactly 10 digits";
    }
    if (inputValues.password === "") {
      error.password = "Password should not be empty";
    }
  
   
    
    return error;
  };
  
  export   const adminloginValidation =(inputValues)=>{
      let error={}
      if (inputValues.mobile === "") {
          error.mobile = "Enter your Mobile Number";
        } else if (!/^\d{10}$/.test(inputValues.mobile)) {
          error.mobile = "Mobile Number should be exactly 10 digits";
        }
        if (inputValues.password === "") {
          error.password = "Password should not be empty";
        }
  
        return error;
  }