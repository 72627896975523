import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Cibilresult from "./Cibilresult";
import axios from "axios";
import { BASE_URL } from "../../../env";

const CibilProfile = ({ onPrevious, cibilScore, maxPrice, userId, selectedYear ,onNext,onLoanAmountChange,SelectedBrand }) => {
  const [showSalried, setShowSalaried] = useState(false);
  const [showSelfEmployeed, setShowSelfEmployeed] = useState(false);
  const [showAgriculture, setshowAgriculture] = useState(false);
  const [showRepayment, setshowRepayment] = useState(false);
  const [selectedResidence, setselectedResidence] = useState(false);
  const [selectedprofile, setSelectedprofile] = useState("");
  const [monthlyIncome, setMonthlyIncome] = useState("");
  const [existingEmi, setExistingEmi] = useState("");
  const [acres, setAcres] = useState("");
  const [existingLoanAmount, setExistingLoanAmount] = useState("");
  const [repaymentEmi, setRepaymentEmi] = useState("");
  const [tenureCompleted, setTenureCompleted] = useState("");
  const [ownType, setOwnType] = useState("");
  const [placeOfStay, setPlaceOfStay] = useState("");
  const [selfEmployedType, setSelfEmployedType] = useState("");
  const [resultLoanAmount, setResultLoanAmount] = useState("");
  const navigate = useNavigate();

  const place = [
    "Ariyalur",
    "Chennai",
    "Coimbatore",
    "Cuddalore",
    "Dharmapuri",
    "Dindigul",
    "Erode",
    "Kallakurichi",
    "Kanchipuram",
    "Kanyakumari",
    "Karur",
    "Krishnagiri",
    "Madurai",
    "Nagapattinam",
    "Namakkal",
    "Nilgiris",
    "Perambalur",
    "Pudukkottai",
    "Ramanathapuram",
    "Ranipet",
    "Salem",
    "Sivaganga",
    "Tenkasi",
    "Thanjavur",
    "Theni",
    "Thoothukudi (Tuticorin)",
    "Tiruchirappalli (Trichy)",
    "Tirunelveli",
    "Tirupathur",
    "Tiruppur",
    "Tiruvallur",
    "Tiruvannamalai",
    "Tiruvarur",
    "Vellore",
    "Viluppuram",
    "Virudhunagar",
  ];
  const handleSalaried = (event) => {
    const selectedValue = event.target.value;
    setSelectedprofile(selectedValue);

    if (selectedValue === "salaried") {
      setShowSalaried(true);
      setShowSelfEmployeed(false);
      setshowRepayment(false);
      setshowAgriculture(false);
    } else if (selectedValue === "Selfemployeed") {
      setShowSelfEmployeed(true);
      setShowSalaried(false);
      setshowRepayment(false);
      setshowAgriculture(false);
    } else if (selectedValue === "agriculture") {
      setshowAgriculture(true);
      setshowRepayment(false);
      setShowSalaried(false);
      setShowSelfEmployeed(false);
    } else if (selectedValue === "repayment") {
      setshowRepayment(true);
      setShowSalaried(false);
      setShowSelfEmployeed(false);
      setshowAgriculture(false);
    }
  };

  const handleResidence = (event) => {
    const selectedValue = event.target.value;
    setselectedResidence(selectedValue);
  };
  const handleSubmit = () => {
    let loanAmount = 0;
    const currentYear = new Date().getFullYear();
    const selectedYearInt = parseInt(selectedYear);

    if (cibilScore < 500) {
      loanAmount = maxPrice * 0.5;
    } else if (
      cibilScore > 600 &&
      selectedprofile === "salaried" &&
      currentYear - selectedYearInt <= 7
    ) {
      loanAmount = maxPrice * 0.9;
    } else if (
      (selectedprofile === "Selfemployeed" &&
        (selfEmployedType === "irt" || selfEmployedType === "gst")) ||
      (selectedprofile === "agriculture" && acres === "10") ||
      (selectedprofile === "repayment" && tenureCompleted <= "12")
    ) {
      loanAmount = maxPrice * 0.9;
    } else if (cibilScore > 600) {
      loanAmount = maxPrice * 0.8;
    }

    // console.log(loanAmount);
    setResultLoanAmount(loanAmount);
    const formdata = {
      user_id:userId,
      profile_type: selectedprofile,
      monthly_income: monthlyIncome,
      existing_emi: existingEmi,
      self_employed_type: selfEmployedType,
      acres: acres,
      existing_loan_amount: existingLoanAmount,
      tenure_completed: tenureCompleted,
      residence_type: selectedResidence,
      own_type: ownType,
      place_of_stay: placeOfStay,
      repayment_emi: repaymentEmi,
      loanAmount:loanAmount,
    };

  
    axios.post (`${BASE_URL}/loanform/user/cibilprofile`,formdata).then((res) => {
        onNext();
    
    })
    .catch((error) => {
      console.error("Error sending data to the server:", error);
     
    });
   
  };
  onLoanAmountChange(resultLoanAmount);
  

  return (
    <div className="cibilcontainer">
      <div className="container cibileProfile formbg">
        <form>
        
          {/*
          <p>Maxprice from valuation :{maxPrice}</p>
          <p>Expected loan amount : {resultLoanAmount}</p> */}
         
{/* 
          <p>{cibilScore}</p> */}
          {/* <p>selectedyear :{selectedYear}</p> */}
          <select
            className="form-select mt-3"
            aria-label="Default select example"
            name="profile_type"
            value={selectedprofile}
            onChange={handleSalaried}
          >
            <option selected>Select Profile Type</option>
            <option value="salaried">Salaried</option>
            <option value="Selfemployeed">Self Employed</option>
            <option value="agriculture">Agriculture</option>
            <option value="repayment">Repayment</option>
          </select>

          {showSalried && (
            <div className="d-flex">
              <label className="sr-only" htmlFor="monthlyincome">
                Monthly Income
              </label>
              <input
                type="text"
                className="form-control mb-2 mr-sm-2"
                id="monthlyincome"
                placeholder="Monthly Income"
                name="monthly_income"
                value={monthlyIncome}
                onChange={(e) => setMonthlyIncome(e.target.value)}
              />

              <label className="sr-only" htmlFor="emi">
                Existing Emi
              </label>
              <input
                type="text"
                className="form-control mb-2 mr-sm-2 mx-2"
                id="emi"
                placeholder="Existing EMI"
                name="existing_emi"
                value={existingEmi}
                onChange={(e) => setExistingEmi(e.target.value)}
              />
            </div>
          )}
          {showSelfEmployeed && (
            <select
              className="form-select mt-3"
              aria-label="Default select example"
              name="self_employed_type"
              value={selfEmployedType}
              onChange={(e) => setSelfEmployedType(e.target.value)}
            >
              <option selected>Self Employed Type</option>
              <option value="irt">ITR</option>
              <option value="gst">GST</option>
              <option value="govt proof">Govt Other Proof</option>
              <option value="none">none</option>
            </select>
          )}
          {showAgriculture && (
            <input
              type="text"
              className="form-control mb-2 mr-sm-2 mx-2"
              placeholder="How Many Acres"
              name="acres"
              value={acres}
              onChange={(e) => setAcres(e.target.value)}
            />
          )}

          {showRepayment && (
            <>
              <div className="d-flex">
                <input
                  type="text"
                  className="form-control mb-2 mr-sm-2"
                  placeholder="Existing Loan Amount"
                  name="existing_loan_amount"
                  value={existingLoanAmount}
                  onChange={(e) => setExistingLoanAmount(e.target.value)}
                />

                <input
                  type="text"
                  className="form-control mb-2 mr-sm-2 mx-2"
                  placeholder="Existing EMI"
                  name="repayment_emi"
                  value={repaymentEmi}
                  onChange={(e) => setRepaymentEmi(e.target.value)}
                />
              </div>
              <input
                type="number"
                className="form-control mb-2 mr-sm-2 mx-2"
                placeholder="Tenure Completed in months"
                name="tenure_completed"
                value={tenureCompleted}
                onChange={(e) => setTenureCompleted(e.target.value)}
              />
            </>
          )}
          <select
            className="form-select mt-3"
            aria-label="Default select example"
            value={selectedResidence}
            onChange={handleResidence}
            name="residence_type"
          >
            <option selected>Select Residence Type</option>
            <option value="own">Own</option>
            <option value="rent">Rent</option>
            <option value="leased">Leased</option>
            <option value="emptyplot">Empty Plot</option>
          </select>
          {selectedResidence === "own" && (
            <select
              className="form-select mt-3"
              aria-label="Default select example"
              name="own_type"
              value={ownType}
              onChange={(e) => setOwnType(e.target.value)}
            >
              <option selected>Own Type</option>
              <option value="self">Self</option>
              <option value="parent">Parent</option>
              <option value="spouse">Spouse</option>
            </select>
          )}
          <select
            className="form-select mt-3"
            aria-label="Default select example"
            name="place_of_stay"
            value={placeOfStay}
            onChange={(e)=>setPlaceOfStay(e.target.value)}
          >
            <option selected>Select Place of Stay</option>
            {place.map((data, index) => (
              <option value={data} key={index}>
                {data}
              </option>
            ))}
          </select>
          <div className="d-flex mx-2 justify-content-center">
            {/* <button className="btn btn-secondary next-btn" onClick={onPrevious}>
              Back
            </button> */}
            <button
              type="button"
              className="btn btn-warning text-white w-50  mx-2 mb-3  px-4"
              onClick={() => handleSubmit()}
            >
            Submit
            </button>
            {/* <button
              type="button"
              className="btn btn-primary text-white mx-2"
              onClick={() => {
                onNext(selectedYear);
              }}
            >
              Next
            </button> */}
          </div>
        </form>
      </div>
    </div>
  );
};

export default CibilProfile;
