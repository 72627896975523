import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import "../../components/datatable/datatable.css";
import axios from "axios";
import { BASE_URL } from "../../../env";
import NavigationHeader from "../NavigationHeader"
const ValidationList = () => {
  const [validationData, setValidationData] = useState([]);
  const [followup, setFollowup] = useState({
    followdate: "",
    followComment: "",
  });

  const [notInterested, setnotInterested] = useState({
    lastcalldate: "",
    Comment: "",
  });
  const [selectedRowId, setSelectedRowId] = useState(null);

  useEffect(() => {
    fetchValidationData();
  }, []);

  const fetchValidationData = () => {
    axios
      .get(`${BASE_URL}/admincarvalidationdata`)
      .then((res) => {
        setValidationData(res.data);
        // console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleFollowupForm = (event) => {
    const { name, value } = event.target;

    setFollowup((prevFollowup) => ({
      ...prevFollowup,
      [name]: value,
    }));
  };

  const handleFollowup = () => {
    if (!followup.followdate || !followup.followComment) {
      alert("Please enter a follow-up date and comments.");
      return;
    }

    const selectedRow = validationData.find((row) => row.id === selectedRowId);

    if (!selectedRow) {
      console.error("Selected row not found.");
      return;
    }

    const followupData = {
      ...selectedRow,
      followupDate: followup.followdate,
      followComment: followup.followComment,
    };

    axios
      .post(`${BASE_URL}/valuationfollowup`, followupData)
      .then((res) => {
        // console.log(res.data);
        setFollowup({ followdate: "", followComment: "" });

        // alert("Follow-up saved successfully.");

        axios
          .delete(`${BASE_URL}/car_validation/${selectedRowId}`)
          .then(() => {
            // console.log("Car validation data deleted successfully.");
            fetchValidationData();
          })
          .catch((deleteError) => {
            console.error("Error deleting car validation data:", deleteError);
          });
        fetchValidationData();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  // handling not interested form

  const handlenotInterestedForm = (event) => {
    const { name, value } = event.target;

    setnotInterested((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // not interested
  const handleNotInterested = () => {
    if (!notInterested.lastcalldate || !notInterested.Comment) {
      alert("Please enter a  date and comments.");
      return;
    }

    const selectedRow = validationData.find((row) => row.id === selectedRowId);

    if (!selectedRow) {
      // console.error("Selected row not found.");
      return;
    }

    const notInterestedData = {
      ...selectedRow,
      lastcalldate: notInterested.lastcalldate,
      Comment: notInterested.Comment,
    };

    axios
      .post(`${BASE_URL}/valuationnotInterested`, notInterestedData)
      .then((res) => {
        // console.log(res.data);
        setnotInterested({ lastcalldate: "", Comment: "" });

        // alert("saved successfully.");

        axios
          .delete(`${BASE_URL}/car_validation/${selectedRowId}`)
          .then(() => {
            console.log("Car validation data deleted successfully.");
            fetchValidationData();
          })
          .catch((deleteError) => {
            console.error("Error deleting car validation data:", deleteError);
          });
        fetchValidationData();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleRowClick = (row) => {
    setSelectedRowId(row.id);
  };
  // handlecompleted

  const handleCompleted = () => {
    const selectedRow = validationData.find((row) => row.id === selectedRowId);

    if (!selectedRow) {
      console.error("Selected row not found.");
      return;
    }

    const completedData = {
      ...selectedRow,
    };

    axios
      .post(`${BASE_URL}/valuation/completed`, completedData)
      .then((res) => {
        console.log(res.data);

        // alert("saved successfully.");

        axios
          .delete(`${BASE_URL}/car_validation/${selectedRowId}`)
          .then(() => {
            console.log("Car validation data deleted successfully.");
            fetchValidationData();
          })
          .catch((deleteError) => {
            console.error("Error deleting car validation data:", deleteError);
          });
        fetchValidationData();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const validationcolumns = [
    { field: "id", headerName: "ID", Width: 100 },

    {
      field: "lastTrialDate",
      headerName: "Last Trial Date",
      width: 150,
      renderCell: (params) => {
        const date = new Date(params.row.lastTrialDate);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");

        const formattedDate = `${day}-${month}-${year}`;

        return <div>{formattedDate}</div>;
      },
    },
    { field: "phoneNumber", headerName: "Phone Number", width: 100 },
    { field: "selectedBrand", headerName: "Selected Brand", width: 200 },
    { field: "selectedModel", headerName: "Selected Model", width: 200 },
    { field: "selectedOwner", headerName: "Selected Owner", width: 150 },
    { field: "selectedVariant", headerName: "Selected Variant", width: 200 },
    { field: "selectedYear", headerName: "Selected Year", width: 200 },
    {
      field: "actions",
      headerName: "Actions",
      width: 400,
      renderCell: (params) => (
        <div>
          <button
            className="btn btn-success"
            data-bs-toggle="modal"
            data-bs-target="#followupmodal"
            onClick={() => handleRowClick(params.row)}
          >
            Follow Up
          </button>
          <button
            className="btn btn-danger mx-3"
            data-bs-toggle="modal"
            data-bs-target="#notinterestedmodal"
            onClick={() => handleRowClick(params.row)}
          >
            Not Interested
          </button>

          <button
            className="btn btn-primary mx-3"
            onClick={() => {
              handleRowClick(params.row);
              handleCompleted();
            }}
          >
            Completed
          </button>
        </div>
      ),
    },
  ];

  return (
    <>
      {" "}
      <div className="datatable">
        <NavigationHeader/>
        <div className="datatableTitle">
          <DataGrid
            className="datagrid"
            columns={validationcolumns}
            rows={validationData}
            pageSize={9}
            rowsPerPageOptions={[9]}
            onRowClick={(params) => handleRowClick(params.row)}
          />
        </div>
      </div>
      {/* Modal for Follow Up */}
      <div
        className="modal fade"
        id="followupmodal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Add a Followup
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="form-floating mb-3">
                <input
                  type="date"
                  className="form-control"
                  id="floatingInput"
                  name="followdate"
                  value={followup.followdate}
                  onChange={handleFollowupForm}
                />
                <label htmlFor="floatingInput">Follow up Date</label>
              </div>
              <div className="form-floating mb-3">
                <textarea
                  className="form-control"
                  placeholder="Leave a comment here"
                  id="floatingTextarea"
                  name="followComment"
                  value={followup.followComment}
                  onChange={handleFollowupForm}
                />
                <label htmlFor="floatingTextarea">Comments</label>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
                onClick={handleFollowup}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* Modal for Not Interested */}
      <div
        className="modal fade"
        id="notinterestedmodal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Not Interested
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="form-floating mb-3">
                <input
                  type="date"
                  className="form-control"
                  id="floatingInput"
                  name="lastcalldate"
                  value={notInterested.lastcalldate}
                  onChange={handlenotInterestedForm}
                />
                <label htmlFor="floatingInput">Last Call Date</label>
              </div>
              <div className="form-floating mb-3">
                <textarea
                  className="form-control"
                  placeholder="Leave a comment here"
                  id="floatingTextarea"
                  name="Comment"
                  value={notInterested.Comment}
                  onChange={handlenotInterestedForm}
                />
                <label htmlFor="floatingTextarea">Comments</label>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
                onClick={handleNotInterested}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ValidationList;
