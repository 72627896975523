import React, { useState, useEffect } from "react";
import axios from "axios";
import { BASE_URL } from "../../../env";
import { DataGrid } from "@mui/x-data-grid";
import "../../components/datatable/datatable.css";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NavigationHeader from "../NavigationHeader";

const TodayFollowup = () => {
  const [followupData, setFollowupData] = useState([]);
  const [show, setShow] = useState(false);
  const [selectedDate, setSelectedDate] = useState("");
  const [comment, setComment] = useState("");
  const [selectedRowId, setSelectedRowId] = useState(null);

  const handleClose = () => {
    setShow(false);
    setSelectedRowId(null);
  };
  const handleShow = () => setShow(true);

  useEffect(() => {
    fetchTodayFollowups();
  }, []);

  const fetchTodayFollowups = () => {
    const currentDate = new Date().toISOString().split("T")[0];

    axios
      .get(`${BASE_URL}/loan/application/todayfollowup/${currentDate}`)
      .then((res) => {
        const updatedData = res.data.map((row, index) => ({
          ...row,
          serialNumber: index + 1,
        }));

        setFollowupData(updatedData);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  // handling selected row
  const handleRowSelection = (params) => {
    setSelectedRowId(params.id);
    handleShow();
  };
  // ADDING FOLLOWUPS

  const handleSaveChanges = () => {
    const dateFormatted = selectedDate.split("-");
    const day = dateFormatted[2];
    const month = dateFormatted[1];
    const year = dateFormatted[0];

    const formattedDateToSend = `${year}-${month}-${day}`;

    if (selectedRowId !== null) {
      const updatedRow = {
        id: selectedRowId,
        followupDate: formattedDateToSend,
        followComment: comment,
      };
      axios
        .put(`${BASE_URL}/loan/followup/update/${updatedRow.id}`, updatedRow)
        .then((res) => {
         
          toast.success("Added to Followup");
          fetchTodayFollowups();
        })
        .catch((err) => {
          console.log(err);
          toast.error("Update Failed");
        });
   
        
    }

    setSelectedDate("");
    setComment("");

    handleClose();
  };
  const followups = [
    {
      field: "serialNumber",
      headerName: "S.NO",
      width: 150,
      headerClassName: "custom-table-header",
    },
    // { field: "id", headerName: "ID", Width: 200 },
    {
      field: "followupDate",
      headerName: "Followup Date",
      width: 300,
      headerClassName: "custom-table-header",
      renderCell: (params) => {
        const date = new Date(params.row.followupDate);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");

        const formattedDate = `${day}-${month}-${year}`;

        return <div>{formattedDate}</div>;
      },
    },
    {
      field: "followComment",
      headerName: "Follow Comment",
      width: 300,
      headerClassName: "custom-table-header",
    },
    {
      field: "first_name",
      headerName: "First Name",
      width: 300,
      headerClassName: "custom-table-header",
    },
    {
      field: "last_name",
      headerName: "Last Name",
      width: 300,
      headerClassName: "custom-table-header",
    },
    {
      field: "phone_number",
      headerName: "Phone Number",
      width: 300,
      headerClassName: "custom-table-header",
    },
    {
      field: "cibilScore",
      headerName: "Cibil",
      width: 300,
      headerClassName: "custom-table-header",
    },
    {
        field: "loanAmount",
        headerName: "Loan Amount",
        width: 300,
        headerClassName: "custom-table-header",
      },
    {
      field: "addFollowupButton",
      headerName: "Add Followup",
      width: 150,
      headerClassName: "custom-table-header",
      renderCell: (params) => (
        <button
          className="btn btn-primary"
          onClick={() => handleRowSelection(params)}
        >
          Add Followup
        </button>
      ),
    },
  ];

  return (
    <div className="datatable">
      <NavigationHeader/>
      <div className="datatableTitle">
        <DataGrid
          className="datagrid"
          columns={followups}
          rows={followupData}
          pageSize={9}
          rowsPerPageOptions={[9]}
        />
      </div>
      {/* MODAL FOR ADDING FOLLOWUP  */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>ADD FOLLOWUP</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Followup Date</Form.Label>
              <Form.Control
                type="date"
                placeholder="Next Followup Date"
                autoFocus
                value={selectedDate}
                onChange={(e) => setSelectedDate(e.target.value)}
              />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Comments</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSaveChanges}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default TodayFollowup;
