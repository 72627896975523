import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import { BASE_URL } from "../../../env";
import { DataGrid } from "@mui/x-data-grid";
import FormDetail from "../loanApplications/modaldetail/FormDetail";
import CibilProfile from "../loanApplications/modaldetail/CibilProfile";
const Tabledata = () => {
  const [showCibil, setShowCibil] = useState(false);
  const [validationData, setValidationData] = useState([]);
  const [showFormDetail, setShowFormDetail] = useState(false);
  const [cibilResultForm, setCibilResultForm] = useState(null);
  const [isFormDetailModalOpen, setIsFormDetailModalOpen] = useState(false);
  const [isCibilDetailModalOpen, setIsCibilDetailModalOpen] = useState(false);

  const [followup, setFollowup] = useState({
    followdate: "",
    followComment: "",
  });
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
 


  useEffect(() => {
    fetchValidationData();
    getCibilResult();
  }, []);

  const getCibilResult = () => {
    axios
      .get(`${BASE_URL}/cibil/data`)
      .then((res) => {
        console.log( "cibil retrived from data");
      })
      .catch((err) => {
        console.error("Error fetching data:", err);
      });
  };

  const fetchValidationData = () => {
    axios
      .get(`${BASE_URL}/loan/application/today`)
      .then((res) => {
      
        const updatedData = res.data.map((row, index) => ({
          ...row,
          serialNumber: index + 1,
          loanAmount: parseFloat(row.loanAmount).toFixed(2)
        }));
        setValidationData(updatedData);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleFollowupForm = (event) => {
    const { name, value } = event.target;

    setFollowup((prevFollowup) => ({
      ...prevFollowup,
      [name]: value,
    }));
  };

  const handleFollowup = () => {
    if (!followup.followdate || !followup.followComment) {
      alert("Please enter a follow-up date and comments.");
      return;
    }

    const selectedRow = validationData.find((row) => row.id === selectedRowId);

    if (!selectedRow) {
      console.error("Selected row not found.");
      return;
    }

    const followupData = {
      ...selectedRow,
      followupDate: followup.followdate,
      followComment: followup.followComment,
    };

    axios
      .post(`${BASE_URL}/loanapplication/add/followup`, followupData)
      .then((res) => {
        
        setFollowup({ followdate: "", followComment: "" });
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const handleRowClick = (row) => {
    setSelectedRowId(row.id);

    setSelectedRow(row);
  };
  const openFormDetailModal = () => {
    setIsFormDetailModalOpen(true);
  };
  const closeFormDetailModal = () => {
    setIsFormDetailModalOpen(false);
  }
  
  const openCibilDetailModal = () => {
    setIsCibilDetailModalOpen(true);
    
  };
  
  const closeCibilDetailModal = () => {
    setIsCibilDetailModalOpen(false);
  };
  const validationcolumns = [
    {
      field: "serialNumber",
      headerName: "S.NO",
      width: 150,
      headerClassName: "custom-table-header",
    },
    // { field: "id", headerName: "ID", Width: 100 },

    {
      field: "first_name",
      headerName: "First Name",
      width: 150,
      headerClassName: "custom-table-header",
    },
    {
      field: "last_name",
      headerName: "Last Name",
      width: 150,
      headerClassName: "custom-table-header",
    },
    {
      field: "phone_number",
      headerName: "Phone Number",
      width: 200,
      headerClassName: "custom-table-header",
    },
    {
      field: "cibilScore",
      headerName: "Cibil",
      width: 100,
      headerClassName: "custom-table-header",
    },
    {
      field: "loanAmount",
      headerName: "Loan Amount",
      width: 200,
      headerClassName: "custom-table-header",
    },

    {
      field: "actions",
      headerName: "Actions",
      width: 700,
      headerClassName: "custom-table-header",
      renderCell: (params) => (
        <div>
          <button
            className="btn btn-warning text-white mx-2"
            onClick={() => {
              setShowCibil(true);
              openCibilDetailModal();
            }}
          >
            View Detail
          </button>
          <button
            className="btn btn-secondary text-white mx-2"
            onClick={() => {
              setShowFormDetail(true);
              openFormDetailModal();
            }}
          >
            Form Detail
          </button>
          <button
            className="btn btn-success"
            data-bs-toggle="modal"
            data-bs-target="#followupmodal"
            onClick={() => handleRowClick(params.row)}
          >
            Follow Up
          </button>
        </div>
      ),
    },
  ];
  return (
    <div className="table_admin">
      <div style={{ height: 400 }}>
        <DataGrid
          rows={validationData}
          columns={validationcolumns}
          pageSize={5}
          rowsPerPageOptions={[5, 10, 20]}
          onRowClick={(params) => handleRowClick(params.row)}
        />
      </div>
      <div
        className="modal fade"
        id="followupmodal" 
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Add a Followup
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="form-floating mb-3">
                <input
                  type="date"
                  className="form-control"
                  id="floatingInput"
                  name="followdate"
                  value={followup.followdate}
                  onChange={handleFollowupForm}
                />
                <label htmlFor="floatingInput">Follow up Date</label>
              </div>
              <div className="form-floating mb-3">
                <textarea
                  className="form-control"
                  placeholder="Leave a comment here"
                  id="floatingTextarea"
                  name="followComment"
                  value={followup.followComment}
                  onChange={handleFollowupForm}
                />
                <label htmlFor="floatingTextarea">Comments</label>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
                onClick={handleFollowup}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    
    {showFormDetail && <FormDetail 
    isOpen={isFormDetailModalOpen}
    onRequestClose={closeFormDetailModal}
    selectedRow={selectedRow}
       
        />}

        {showCibil && <CibilProfile
        isOpen={isCibilDetailModalOpen}
        onRequestClose={closeCibilDetailModal}
         selectedRow={selectedRow}/>}
    </div>
  );
};

export default Tabledata;
