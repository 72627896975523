import React, { useEffect, useState } from "react";
import axios from "axios";
import "./carmodels.css";
import { BASE_URL } from "../../../env";
import "./CarModelTable";
import CarModelTable from "./CarModelTable";
import NavigationHeader from "../../components/NavigationHeader";

const Carmodels = () => {
  const [brands, setBrands] = useState([]);
  const [newBrand, setNewBrand] = useState("");
  const [selectedBrand, setSelectedBrand] = useState("");
  const [model, setModel] = useState([]);
  const [newModel, setNewModel] = useState("");
  const [selectedModel, setSelectedModel] = useState("");
  const [newVariant, setNewVariant] = useState("");
  const [variants, setVariants] = useState([]);
  const [price, setPrice] = useState("");
  const [error, setError] = useState("");
  
  useEffect(() => {
    axios
      .get(`${BASE_URL}/carmodelsbrand`)
      .then((res) => {
        setBrands(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleBrandChange = (e) => {
    const brand_id = e.target.value;

    setSelectedBrand(parseInt(brand_id));

    axios
      .get(`${BASE_URL}/admincarmodel/${brand_id}`)
      .then((response) => {
       
        setModel(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleAddBrand = () => {

    if (!newBrand.trim()) {
    
      setError("Brand name should not be empty");
      return;
    }

    axios
      .post(`${BASE_URL}/addnewbrand/`, { brand_name: newBrand })
      .then((res) => {
      
        document.getElementById('brandmodal').classList.remove('show');
        document.querySelectorAll('.modal-backdrop').forEach(el => el.classList.remove('modal-backdrop'));
        setBrands([...brands, res.data]);
        // setNewBrand("");
        // handleBrandChange();
        window.location.reload();
     

      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleModelChange = (e) => {
    const model_id = e.target.value;

    setSelectedModel(parseInt(model_id));

    axios
      .get(`${BASE_URL}/carvariants/${model_id}`)
      .then((res) => {
        setVariants(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleAddModel = () => {
   
    if (!newModel.trim()) {
    
      return;
    }
    axios
      .post(`${BASE_URL}/addnewmodel`, {
        model_name: newModel,
        brand_id: selectedBrand,
      })
      .then((res) => {
       
        setNewModel("");
        document.getElementById('thirdmodal').classList.remove('show');
        document.querySelectorAll('.modal-backdrop').forEach(el => el.classList.remove('modal-backdrop'));
        window.location.reload();
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  const handleAddVariant = () => {
    if (!newVariant.trim()) {
    
      return;
    }

    axios
      .post(`${BASE_URL}/addnewvariant`, {
        modelID: selectedModel,
        variantName: newVariant,
        price: price,
      })
      .then((res) => {
       
        setNewVariant("");
        setPrice("");
        document.getElementById('fourthmodal').classList.remove('show');
        document.querySelectorAll('.modal-backdrop').forEach(el => el.classList.remove('modal-backdrop'));
        window.location.reload();
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  return (
    <>
      <NavigationHeader/>
      <button
        type="button"
        className="btn btn-success "
        data-bs-toggle="modal"
        data-bs-target="#firstModal"
      >
        ADD NEW
      </button>
  
      <div className="admincarmodel">
        <div
          className="modal fade"
          id="firstModal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h1 class="modal-title fs-5" id="exampleModalLabel">
                  Modal title
                </h1>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">
                <div className="carmodels">
                  <div className="form-floating ">
                    <select
                      className="form-select"
                      id="floatingSelect"
                      aria-label="Floating label select example"
                      value={selectedBrand}
                      onChange={handleBrandChange}
                    >
                      <option value="">Select Brand</option>
                      {brands.map((brand) => (
                        <option key={brand.brand_id} value={brand.brand_id}>
                          {brand.brand_name}
                        </option>
                      ))}
                    </select>
                    <label for="floatingSelect">Brand</label>
                    <button
                      className="btn btn-primary"
                      type="button"
                      data-bs-toggle="modal"
                      data-bs-target="#brandmodal"
                    >
                      Add new brand
                    </button>
                  </div>

                  <div className="form-floating mt-3">
                    <select
                      className="form-select"
                      id="floatingSelect"
                      aria-label="Floating label select example"
                      value={selectedModel}
                      onChange={handleModelChange}
                    >
                      <option value="">Select Model</option>
                      {model.map((models) => (
                        <option key={models.model_id} value={models.model_id}>
                          {models.model_name}
                        </option>
                      ))}
                    </select>
                    <button
                      className="btn btn-primary"
                      type="button"
                      data-bs-toggle="modal"
                      data-bs-target="#thirdmodal"
                    >
                      Add new model
                    </button>
                    <label for="floatingSelect">Model</label>
                  </div>

                  <div className="form-floating mt-3">
                    <select
                      className="form-select"
                      id="floatingSelect"
                      aria-label="Floating label select example"
                    >
                      <option value="">List of Variants</option>
                      {variants.map((variant) => (
                        <option
                          key={variant.variant_id}
                          value={variant.variant_id}
                        >
                          {variant.variant_name}
                        </option>
                      ))}
                    </select>
                    <button
                      className="btn btn-primary"
                      type="button"
                      data-bs-toggle="modal"
                      data-bs-target="#fourthmodal"
                    >
                      Add new variant
                    </button>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          tabIndex="-1"
          id="brandmodal"
          aria-labelledby="editModalLabel"
          aria-hidden="true"
          data-bs-backdrop="static" 
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h6 className="modal-title" id="editModalLabel">
                  Add New Brand
                </h6>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <input
                  type="text"
                  placeholder="Brand Name"
                  name="brand_name"
                  value={newBrand}
                  onChange={(e) => setNewBrand(e.target.value)}
                ></input>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>

                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleAddBrand}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          tabIndex="-1"
          id="thirdmodal"
          aria-labelledby="editModalLabel"
          aria-hidden="true"
          data-bs-backdrop="static"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h6 className="modal-title" id="editModalLabel">
                  Add New Model
                </h6>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <input
                  type="text"
                  placeholder="Model Name"
                  name="model_name"
                  value={newModel}
                  onChange={(e) => setNewModel(e.target.value)}
                ></input>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>

                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleAddModel}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          tabIndex="-1"
          id="fourthmodal"
          aria-labelledby="editvariantLabel"
          aria-hidden="true"
          data-bs-backdrop="static"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h6 className="modal-title" id="editvariantLabel">
                  Add New Variant
                </h6>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <input
                  type="text"
                  placeholder="Variant Name"
                  name="variant_name"
                  value={newVariant}
                  onChange={(e) => setNewVariant(e.target.value)}
                ></input>

                <input
                  type="text"
                  placeholder="price"
                  name="price"
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                ></input>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>

                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleAddVariant}
                  data-bs-dismiss="modal"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CarModelTable />
    </>
  );
};

export default Carmodels;
