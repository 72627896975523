import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./adminform.css";
import { adminloginValidation } from "./AdminValidation";
import axios from "axios";
import { BASE_URL } from "../../../env";
import { toast, ToastContainer } from "react-toastify";
function AdminLogin() {
  const navigate = useNavigate();
  const [inputValues, setInputValues] = useState({
    mobile: "",
    password: "",
  });
  const [error, setError] = useState({});

  const handleInput = (event) => {
    setInputValues((prev) => ({
      ...prev,
      [event.target.name]: [event.target.value],
    }));
    // console.log(inputValues);
  };
const handleSubmit = (event) => {
  event.preventDefault();

  const loginError = adminloginValidation(inputValues);
  setError(loginError);

  if (Object.keys(loginError).length === 0) {
    axios
      .post(`${BASE_URL}/adminlogin`, inputValues)
      .then((res) => {
        if (res.data.message === "Logged in successfully") {
          navigate("/admin/dashboard");
        } else {
          toast.error("Login failed");
        }
      })
      .catch((err) => {
        console.error("Error in Admin Login:", err);
        toast.error("Error in Admin Login");
      });
  }
};

  return (
    <section>
      <div className="admin-login">
        <div className=" text-center">
      <div >
      <form
            onSubmit={handleSubmit}
            className="rounded bg-white shadow py-5 px-4"
            style={{width:"350px"}}
          >
            <h3 className="text-dark fw-bolder fs-4 mb-2">Sign In</h3>

            <div className="form-floating mb-3">
              <input
                type="number"
                className="form-control"
                id="mobile"
                name="mobile"
                placeholder="Enter Your Mobile Number"
                onChange={handleInput}
              />
              <label htmlFor="mobile">Mobile Number</label>
              {error.mobile && (
                <span className="error-admin">{error.mobile}</span>
              )}
            </div>

            <div className="form-floating mb-3">
              <input
                type="password"
                className="form-control"
                id="password"
                placeholder="Enter Your Password"
                name="password"
                onChange={handleInput}
              />
              <label htmlFor="password">Password</label>
              {error.password && (
                <span className="error-admin">{error.password}</span>
              )}
            </div>

            <button
              type="submit"
              className="btn btn-primary submit_btn w-100 my-4"
            >
              Login
            </button>
          </form>
      </div>
          
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </section>
  );
}

export default AdminLogin;
