import React ,{useRef}from "react";
import { responseData } from "./responseData";
import "./cibildetail.css";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
 
const CibilDetail = ({ selectedRow }) => {
const contentRef =useRef();

const handleDownload = async () => {
  const input = contentRef.current;
  const pdf = new jsPDF("p", "mm", "a4");
  const originalPdfWidth = pdf.internal.pageSize.getWidth();
  const originalPdfHeight = pdf.internal.pageSize.getHeight();
  const pdfWidth = originalPdfWidth * 0.9;
  const pdfHeight = originalPdfHeight * 0.9;
  const xOffset = (originalPdfWidth - pdfWidth) / 2;
  const yOffset = (originalPdfHeight - pdfHeight) / 2;

  const sections = input.querySelectorAll(".section");
  let heightLeft = pdfHeight;

  for (let i = 0; i < sections.length; i++) {
    const section = sections[i];
    const canvas = await html2canvas(section, {
      useCORS: true,
      scale: 1.5,
    });
    const imgData = canvas.toDataURL("image/jpeg", 0.7);
    const imgProps = pdf.getImageProperties(imgData);
    const imgHeight = (imgProps.height * pdfWidth) / imgProps.width;

    if (i === 0) {
      pdf.addImage(imgData, "JPEG", xOffset, yOffset, pdfWidth, imgHeight);
      heightLeft -= imgHeight;
    } else {
      if (heightLeft < imgHeight) {
        pdf.addPage();
        heightLeft = pdfHeight;
      }
      pdf.addImage(
        imgData,
        "JPEG",
        xOffset,
        yOffset + (pdfHeight - heightLeft),
        pdfWidth,
        imgHeight
      );
      heightLeft -= imgHeight;
    }
  }

  pdf.setProperties({
    title: "Document",
    subject: "Generated Document",
    author: "Your Name",
    keywords: "generated, javascript, web 2.0, ajax",
    creator: "Your App",
  });

  pdf.save("Cibil Report .pdf");
};


  const formatResponse = (selectedRow) => {
    const dataObject = JSON.parse(selectedRow.data);

    if (dataObject.data && dataObject.data.Equifax_Report) {
      const {
        code,
        status,
        mess,
        data: {
          Equifax_Report: {
            InquiryResponseHeader,
            InquiryRequestInfo,
            CCRResponse: {
              CIRReportDataLst: [
                {
                  CIRReportData: {
                    IDAndContactInfo,
                    RetailAccountDetails,
                    RetailAccountsSummary,
                    ScoreDetails,
                  },
                },
              ],
            },
          },
        },
      } = dataObject;

      return {
        InquiryResponseHeader,
        InquiryRequestInfo,
        IDAndContactInfo,
        RetailAccountDetails,
        RetailAccountsSummary,
        ScoreDetails,
      };
    } else if (dataObject.report) {
      const {
        report: {
          InquiryResponseHeader,
          InquiryRequestInfo,
          CCRResponse: {
            CIRReportDataLst: [
              {
                CIRReportData: {
                  IDAndContactInfo,
                  RetailAccountDetails,
                  RetailAccountsSummary,
                  ScoreDetails,
                },
              },
            ],
          },
        },
      } = dataObject;

      return {
        InquiryResponseHeader,
        InquiryRequestInfo,
        IDAndContactInfo,
        RetailAccountDetails,
        RetailAccountsSummary,
        ScoreDetails,
      };
    } else {
      return null;
    }
  };

  const formattedResponse = formatResponse(selectedRow);


  const {
    InquiryResponseHeader,
    InquiryRequestInfo,
    IDAndContactInfo,
    RetailAccountDetails,
    RetailAccountsSummary,
    ScoreDetails,
  } = formattedResponse;


  if (!formattedResponse) {
    return <div>No data available</div>;
  }

  
  return (
    <>
    <div style={{display:"flex",justifyContent:"flex-start"}}>
    <button
          className="btn btn-success mb-5"
          onClick={handleDownload}
          
        >
         Download PDF
        </button>
    </div>
       
      <div className="cibil-report " ref={contentRef}>
        <div className="section">
        <h5 className="credit-heading">Consumer CIR</h5>
        <div className="report-info ">
          <p>
            <strong>Consumer : </strong>
            {IDAndContactInfo.PersonalInfo.Name.FullName}
          </p>
          <p>
            <strong>Report Order No : </strong>
            {InquiryResponseHeader.ReportOrderNO}
          </p>
          <p>
            <strong>Date :</strong>
            {InquiryResponseHeader.Date}
          </p>
          <p>
            <strong>Time :</strong>
            {InquiryResponseHeader.Time}
          </p>
        </div>
        </div>
      <div className="section">
 {/* personal information */}
 <div >
          <div className="credit-heading">
            <h5>Consumer Information</h5>
          </div>
          <div className="d-flex flex-wrap justify-content-between mx-2">
            <div>
              <p>
                <strong>Full Name : </strong>
                {IDAndContactInfo.PersonalInfo.Name.FullName}
              </p>

              <p>
                <strong>Date of Birth: </strong>
                {IDAndContactInfo.PersonalInfo.DateOfBirth}
              </p>
            </div>
            <div>
              <p>
                <strong>Gender : </strong>
                {IDAndContactInfo.PersonalInfo.Gender}
              </p>
              <p>
                <strong>Age : </strong>
                {IDAndContactInfo.PersonalInfo.Age.Age}
              </p>
 
            </div>
          </div>
        </div>
        {/* personal information */}
      </div>
       <div className="section">
 {/* score details */}
 <div >
          <h5 className="credit-heading">Score Details</h5>
          <div className="contact-info d-flex flex-wrap justify-content-between ">
            <p>
              <strong>ScoreName :</strong> {ScoreDetails[0].Name}
            </p>
            <p>
              <strong>Cibil Score :</strong> {ScoreDetails[0].Value}
            </p>
            <ul>
              <p>
                {" "}
                <strong>Scoring Factors </strong>
              </p>
              <li>PRESENCE OF DELINQUENCY</li>
              <li> PRESENCE OF DELINQUENCY IN T HE RECENT PAST</li>
              <li>LOW CREDIT AGE POSSIBLE</li>
            </ul>
          </div>
        </div>
        {/* score details */}
       </div>
 <div className="section">
        {/* contact information */}
        <div >
          <h5 className="credit-heading">Contact Information</h5>
          <div className="d-flex flex-wrap justify-content-between">
            <div className="mx-2 ">
              {IDAndContactInfo.PhoneInfo.map((phone, index) => (
                <div key={index}>
                  <p>
                    <strong>Phone Type :</strong> {phone.typeCode}
                  </p>
                </div>
              ))}
            </div>
            <div className="mx-2 ">
              {IDAndContactInfo.PhoneInfo.map((phone, index) => (
                <div key={index}>
                  <p>
                    <strong>Number:</strong> {phone.Number}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* contact information */}

 </div>
 <div className="section">

        {/* address information */}
        <div className="address-info">
          <h5 className="credit-heading">Address Information</h5>
          <div className="mx-2 my-2 d-flex flex-wrap justify-content-between ">
            {IDAndContactInfo.AddressInfo.map((address, index) => (
              <div key={index} className="w-50">
                <p className="address-info">
                  <strong>Address :</strong> {address.Address}
                </p>
                <p className="address-info">
                  <strong>State :</strong> {address.State}
                </p>
                <p className="address-info">
                  <strong>Postal :</strong> {address.Postal}
                </p>
              </div>
            ))}
          </div>
        </div>
        {/* address information */}
 </div>
       

        {/* identity information */}
        {/* <div>
          <h5 className="credit-heading">Identity Information</h5>
          <div className=" mx-2">
            <p>
              <strong>ID Type : PAN Id</strong>
            </p>

            {IDAndContactInfo.IdentityInfo.PANId.map((pan, index) => (
              <p key={index}>
                <p>
                  <strong>ID Number:</strong> {pan.IdNumber}
                </p>
              </p>
            ))}
          </div>
        </div> */}
        {/* identity information */}
        <div className="section">
                  {/* accounts summary information */}

        <div className="address-info ">
          <h5 className="credit-heading ">Accounts Summary Information</h5>
          <div className="d-flex justify-content-between flex-wrap gap-5 mx-2 ">
            <div>
              <p className="text-start">
                <strong className="text-info">ACCOUNT TYPE</strong>
              </p>
              <p className="text-start">
                <strong>All Accounts</strong>
              </p>
            </div>
            <div>
              <p className="text-start">
                <strong className="text-info">ACCOUNTS </strong>
              </p>
              <p className="text-start">
                <strong className="text-secondary address-info">Total : </strong>
                <strong> {RetailAccountsSummary.NoOfAccounts}</strong>
              </p>
              <p className="text-start">
                <strong className="text-secondary address-info">ActiveAccounts : </strong>
                <strong> {RetailAccountsSummary.NoOfActiveAccounts}</strong>
              </p>
              <p className="text-start">
                <strong className="text-secondary address-info">TotalPastDue : </strong>
                <strong> {RetailAccountsSummary.TotalPastDue}</strong>
              </p>
              <p className="text-start">
                <strong className="text-secondary address-info">NoOfWriteOffs : </strong>
                <strong> {RetailAccountsSummary.NoOfWriteOffs}</strong>
              </p>
              <p className="text-start address-info">
                <strong className="text-secondary">
                  NoOfPastDueAccounts :{" "}
                </strong>
                <strong> {RetailAccountsSummary.NoOfPastDueAccounts}</strong>
              </p>
              <p className="text-start address-info">
                <strong className="text-secondary">OldestAccount : </strong>
                <strong> {RetailAccountsSummary.OldestAccount}</strong>
              </p>
              <p className="text-start address-info">
                <strong className="text-secondary">RecentAccount : </strong>
                <strong> {RetailAccountsSummary.RecentAccount}</strong>
              </p>
              <p className="text-start address-info">
                <strong className="text-secondary">TotalHighCredit : </strong>
                <strong> {RetailAccountsSummary.TotalHighCredit}</strong>
              </p>
              <p className="text-start address-info">
                <strong className="text-secondary">TotalCreditLimit : </strong>
                <strong> {RetailAccountsSummary.TotalCreditLimit}</strong>
              </p>
            </div>
            <div>
              <p className="text-start address-info">
                <strong className="text-info ">ADVANCES </strong>
              </p>
              <p className="text-start address-info">
                <strong className="text-secondary">
                  SingleHighestSanctionAmount :{" "}
                </strong>
                <strong>
                  {" "}
                  {RetailAccountsSummary.SingleHighestSanctionAmount}
                </strong>
              </p>
              <p className="text-start address-info">
                <strong className="text-secondary">
                  SingleHighestBalance : :{" "}
                </strong>
                <strong>{RetailAccountsSummary.SingleHighestBalance}</strong>
              </p>
              <p className="text-start address-info">
                <strong className="text-secondary">
                  AverageOpenBalance :{" "}
                </strong>
                <strong> {RetailAccountsSummary.AverageOpenBalance}</strong>
              </p>
              <p className="text-start address-info">
                <strong className="text-secondary">
                  TotalBalanceAmount :{" "}
                </strong>
                <strong> {RetailAccountsSummary.TotalBalanceAmount}</strong>
              </p>
              <p className="text-start address-info"> 
                <strong className="text-secondary">
                  TotalSanctionAmount :{" "}
                </strong>
                <strong> {RetailAccountsSummary.TotalSanctionAmount}</strong>
              </p>
              <p className="text-start address-info">
                <strong className="text-secondary">
                  TotalMonthlyPaymentAmount :{" "}
                </strong>
                <strong>
                  {" "}
                  {RetailAccountsSummary.TotalMonthlyPaymentAmount}
                </strong>
              </p>
              <p className="text-start address-info">
                <strong className="text-secondary">
                  MostSevereStatusWithIn24Months :{" "}
                </strong>
                <strong>
                  {" "}
                  {RetailAccountsSummary.MostSevereStatusWithIn24Months}
                </strong>
              </p>

              <p className="text-start address-info">
                <strong className="text-secondary">
                  SingleHighestCredit :{" "}
                </strong>
                <strong> {RetailAccountsSummary.SingleHighestCredit}</strong>
              </p>
            </div>
          </div>
        </div>

        {/* accounts summary information */}
        <h5 className="credit-heading address-info">Accounts Details Information</h5>
        </div>

 {/* accounts Details information */}
     <div >
       

          <div className="mx-2 address-info ">
      
            <div >
              {RetailAccountDetails.map((account, index) => (
                <div key={index} className="section">
                  <div className=" d-flex justify-content-between flex-wrap ">
                    <div className="address-info ">
                      <p className="text-start address-info">
                        <strong>Seq :</strong> {account.seq}
                      </p>
                      <p className="text-start address-info">
                        <strong>Loan id :</strong> {account.id}
                      </p>
                      <p className="text-start address-info">
                        <strong>AccountNumber :</strong> {account.AccountNumber}
                      </p>
                      <p className="text-start address-info">
                        <strong>Finance Name :</strong> {account.Institution}
                      </p>
                    </div>
                    <div>
                      <p className="text-start address-info">
                        <strong>AccountType :</strong> {account.AccountType}
                      </p>
                      <p className="text-start address-info"> 
                        <strong>OwnershipType :</strong> {account.OwnershipType}
                      </p>
                      <p className="text-start address-info"> 
                        <strong>Balance :</strong> {account.Balance}
                      </p>
                      <p className="text-start address-info">
                        <strong>PastDueAmount :</strong> {account.PastDueAmount}
                      </p>
                      <p className="text-start address-info">
                        <strong>Open :</strong> {account.Open}
                      </p>
                      <p className="text-start address-info">
                        <strong>SanctionAmount :</strong> {account.SanctionAmount}
                      </p>
                      <p className="text-start address-info">
                        <strong>DateReported:</strong> {account.DateReported}
                      </p>
                      <p className="text-start address-info">
                        <strong>DateOpened :</strong> {account.DateOpened}
                      </p>
                    </div>

                    <div>
                      <p className="text-start address-info">
                        <strong >InterestRate :</strong> {account.InterestRate}
                      </p>
                      <p className="text-start address-info">
                        <strong>RepaymentTenure :</strong>{" "}
                        {account.RepaymentTenure}
                      </p>
                      <p className="text-start address-info">
                        <strong>InstallmentAmount :</strong>{" "}
                        {account.InstallmentAmount}
                      </p>
                      <p className="text-start address-info">
                        <strong>AccountStatus :</strong> {account.AccountStatus}
                      </p>
                      <p className="text-start address-info">
                        <strong>AssetClassification :</strong>{" "}
                        {account.AssetClassification}
                      </p>
                      <p className="text-start address-info">
                        <strong>source :</strong> {account.source}
                      </p>
                    </div>
                  </div>
                  <h5>History 48 months</h5>
                  <div className="my-4 d-flex  flex-wrap gap-2 ">
                   
                    {account.History48Months.map((history, idx) => (
                      <div key={idx} className="payment-status address-info text-center ">
                        <span className="text-center">
                          <strong> {history.PaymentStatus}</strong> <br />{" "}
                          {history.key}
                          <br />
                        </span>
                      </div>
                    ))}
                  </div>
                  <h5 className="credit-heading"></h5>
                </div>
              ))}
              
            </div>
          </div>
        </div>
        {/* accounts Details information */}


      
      </div>
      {/* end  */}
    </>
  );
};

export default CibilDetail;
