import React from "react";
import "./adminsidebar.css";
import { useNavigate } from "react-router-dom";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import ChecklistIcon from "@mui/icons-material/Checklist";
import FilterListOffIcon from "@mui/icons-material/FilterListOff";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
const AdminSidebar = () => {
  const navigate = useNavigate();

  return (
    <div className="sidebar">
      <ul>
        <p style={{ cursor: 'pointer' }}>Loan Applied Customers</p>
        <li onClick={()=>navigate('/admin/loan/today/followup')} style={{ cursor: 'pointer' }}>
          <BookmarkIcon className="sidebar-icons" style={{ color: "blue" }} />
          Today Followup
        </li>
        <li onClick={() => navigate("/admin/loan/followuplist")} style={{ cursor: 'pointer' }}>
          <ChecklistIcon className="sidebar-icons" style={{ color: "green" }} />
          Followup List
        </li>
        <li onClick={() => navigate("/admin/loan/notinterestedlist")} style={{ cursor: 'pointer' }}>
          <FilterListOffIcon
            className="sidebar-icons"
            style={{ color: "red" }}
          />
          Not Interested List
        </li>
        <li onClick={() => navigate("/admin/loan/completionlist")} style={{ cursor: 'pointer' }}>
          <CheckCircleIcon
            className="sidebar-icons"
            style={{ color: "green" }}
          />
          Completed List
        </li>
      </ul>
      <ul>
        <p>Car Valuation Customers</p>
        <li onClick={() => navigate("/admin/todayfollowup")} style={{ cursor: 'pointer' }}>
          <BookmarkIcon className="sidebar-icons" style={{ color: "blue" }} />
          Today Followup
        </li>
        <li onClick={() => navigate("/admin/loanfollowup")} style={{ cursor: 'pointer' }}>
          <ChecklistIcon className="sidebar-icons" style={{ color: "green" }} />
          Followup List
        </li>
        <li onClick={() => navigate("/admin/loan/notinterested")} style={{ cursor: 'pointer' }}>
          <FilterListOffIcon
            className="sidebar-icons"
            style={{ color: "red" }}
          />
          Not Interested List
        </li>
        <li onClick={() => navigate("/admin/loan/completed")} style={{ cursor: 'pointer' }}>
          <CheckCircleIcon
            className="sidebar-icons"
            style={{ color: "green" }}
          />
          Completed List
        </li>
      </ul>
    </div>
  );
};

export default AdminSidebar;
