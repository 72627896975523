import React from "react";
import { useNavigate } from "react-router-dom";

function NavigationHeader() {
  const navigate = useNavigate();

  return (
    <div className="d-flex justify-content-start m-4">
      <button
        type="button"
        className="btn btn-primary"
        onClick={() => navigate("/admin/dashboard")}
      >
        Back
      </button>
    </div>
  );
}

export default NavigationHeader;
