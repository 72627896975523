import React, { useState } from "react";
import "./Dealerform.css";
import "./dealerValidation";
import { useNavigate } from "react-router-dom";
import { registerValidation } from "./dealerValidation";
import axios from "axios";
import { BASE_URL } from "../../env";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Toast from "../../components/Toast";
function DealerSignUp() {
  const navigate = useNavigate();
  const [inputValues, setInputValues] = useState({
    firstName: "",
    lastName: "",
    mobile: "",
    password: "",
  });
  const [error, setError] = useState({});

  const handleInput = (event) => {
    setInputValues((prev) => ({
      ...prev,
      [event.target.name]: [event.target.value],
    }));
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    const validationErrors = registerValidation(inputValues);
    setError(validationErrors);

    // setError(registerValidation(inputValues))
    // console.log(inputValues)
    if (Object.keys(validationErrors).length === 0) {
      axios
        .post(`${BASE_URL}/dealersignup`, inputValues)
        .then((res) => {
          setInputValues({
            firstName: "",
            lastName: "",
            mobile: "",
            password: "",
          });
          toast.success("Registered successfully!", {
            position: toast.POSITION.TOP_CENTER,
          });
          navigate("/dealerlogin");
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  return (
    <section className="wrapper-dealerlogin">
      <div className="login-dealer">
        <div className="formlogin-dealer">
        <div className="back-button">
          <i className="fas fa-chevron-left "><span  onClick={() => navigate("/dealerlogin")}> Back</span></i>
          </div>
          <form className="register-dealer" onSubmit={handleSubmit}>
            <div className="login_field">
              <i className="login_icon fas fa-user"></i>
              <input
                className="login_input"
                placeholder="First Name"
                name="firstName"
                onChange={handleInput}
              />
              <div>
                {error.firstName && (
                  <span className="error-dealer">{error.firstName}</span>
                )}
              </div>
            </div>

            <div className="login_field">
              <i className="login_icon fas fa-user"></i>
              <input
                className="login_input"
                placeholder=" Last Name"
                name="lastName"
                onChange={handleInput}
              />
              <div>
                {error.lastName && (
                  <span className="error-dealer">{error.lastName}</span>
                )}
              </div>
            </div>

            <div className="login_field">
              <i className="login_icon  fa fa-mobile" aria-hidden="true"></i>
              <input
                className="login_input"
                id="mobilenumber"
                placeholder="Mobile Number"
                name="mobile"
                onChange={handleInput}
              />
              <div>
                {" "}
                {error.mobile && (
                  <span className="error-dealer">{error.mobile}</span>
                )}
              </div>
            </div>

            <div className="login_field">
              <i className=" login_icon fa fa-key" aria-hidden="true"></i>
              <input
                type="password"
                className="login_input"
                id="password"
                placeholder=" Password"
                name="password"
                onChange={handleInput}
              />
              <div>
                {error.password && (
                  <span className="error-dealer">{error.password}</span>
                )}
              </div>
            </div>

            <button type="submit" className="login_submit">
              <span className="button-text">Register Dealer</span>
              <i className="button_icon fas fa-chevron-right"></i>
            </button>
   
          </form>
        </div>
        <div className="screen__background">
          <span class="screen__background__shape screen__background__shape4"></span>
          <span class="screen__background__shape screen__background__shape3"></span>
          <span class="screen__background__shape screen__background__shape2"></span>
          <span class="screen__background__shape screen__background__shape1"></span>
        </div>
      </div>
      <Toast />
    </section>
  );
}

export default DealerSignUp;
