import React from "react";
import "./hero.css";
import { useNavigate } from "react-router-dom";
import Calculator from "../../components/calculator/Calculator";
import Scrollbtn from "../../components/utilitis/Scrollbtn";
import Eligibility from "../../components/eligibilty/Eligiblity";
import Partners from "../channel_partners/Partners";
import Fotter from "../../components/Footer/Fotter";
import Contact from "../contactPage/Contact";
import Toast from "../../components/Toast";
import Slider from "react-slick";
import { Container } from "reactstrap";

export default function Hero() {
  const navigate = useNavigate();

  const settings = {
    fade: true,
    speed: 2000,
    autoplaySpeed: 3000,
    infinite: true,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnHover: false,
  };
  return (
    <>
      <Slider {...settings} className="hero__slider">
        <div className="slider__item slider__item-01 mt0">
          <Container>
            <div className="slider__content ">
              <h4 className="text-light mb-3">For Used Car Loans</h4>

              <h1 className="text-light mb-4">
                {" "}
                Starting From Lowest Used Car Rate
              </h1>

              <div>
                <button
                  className="btn reserve__btn mt-4"
                  onClick={() => navigate("/usedcarvalidation")}
                >
                  Used Car Valuation
                </button>
                <button
                  className="btn reserve__btn mt-4 mx-2 "
                  onClick={() => navigate("/loanform/user/applyloan")}
                >
                  Apply For Loan
                </button>
              </div>
            </div>
          </Container>
        </div>

        <div className="slider__item slider__item-02 mt0">
          <Container>
            <div className="slider__content ">
              <h4 className="text-light mb-3">For Used Car Loans</h4>

              <h1 className="text-light mb-4"> Settlement done 24 * 7</h1>

              <div>
                <button
                  className="btn reserve__btn mt-4"
                  onClick={() => navigate("/usedcarvalidation")}
                >
                  Used Car Valuation
                </button>
                <button
                  className="btn reserve__btn mt-4 mx-2 "
                  onClick={() => navigate("/loanform/user/applyloan")}
                >
                  Apply For Loan
                </button>
              </div>
            </div>
          </Container>
        </div>

        <div className="slider__item slider__item-03 mt0">
          <Container>
            <div className="slider__content ">
              <h4 className="text-light mb-3">For Used Car Loans</h4>

              <h1 className="text-light mb-4">
                {" "}
                Cibil issue cases also considered{" "}
              </h1>
              <div>
                <button
                  className="btn reserve__btn mt-4"
                  onClick={() => navigate("/usedcarvalidation")}
                >
                  Used Car Valuation
                </button>
                <button
                  className="btn reserve__btn mt-4 mx-2 "
                  onClick={() => navigate("/loanform/user/applyloan")}
                >
                  Apply For Loan
                </button>
              </div>
            </div>
          </Container>
        </div>
      </Slider>

      <Calculator />
      <Toast />
      <Eligibility />
      <Partners />
      <Contact />
      {/* <Contactform/> */}
      <Scrollbtn />
      <Fotter />
    </>
  );
}
