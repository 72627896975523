import React from 'react'
import './preloader.css'

const Preloader = () => {
    return (
        <div className='preloader-container'>
   <div class="loader"></div>
        </div>
     
    )
}

export default Preloader