import React, { useEffect, useState } from "react";
// import ReactSpeedometer from "react-d3-speedometer";
import "./responsive.css";
import ReactConfetti from "react-confetti";
import congratulation from "../../../asserts/hero/congratulation.png";
const Cibilresult = ({
  loanAmount,
  cibilScore,
  SelectedBrand,
  SelectedModel,
  SelectedVariant,
  selectedYear,
}) => {
  // console.log("cibilscore from result information", cibilScore);
  const numericValue = parseInt(loanAmount);
  const [windowDimention, setDimention] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const detectSize = () => {
    setDimention({ width: window.innerWidth, height: window.innerHeight });
  };
  useEffect(() => {
    window.addEventListener("resize", detectSize);
    return () => {
      window.removeEventListener("resize", detectSize);
    };
  }, [windowDimention]);

  
  return (
    <div className="laststep">
      <ReactConfetti
        width={windowDimention.width}
        height={windowDimention.height}
        tweenDuration={1000}
      />

      <div className="cibilresult card" style={{ backgroundColor: "#FFF8C9" }}>
        <div>
          <div style={{ fontSize: "15px",marginTop:"-4rem"}}>
            <img src={congratulation} className="congrats-text" alt="congralations"/>
            <div className="cibil-text">
              <p className="result-text text-center">
                Your Eligiblity of {SelectedBrand} {SelectedModel}{" "}
                {SelectedVariant} {selectedYear}
              </p>
              <p className="results-text text-center">
                Your Loan Amount is {numericValue.toFixed(0)} for your car{" "}
              </p>
              <p className="results-text text-center">
                Your cibil Score is : {cibilScore}
              </p>

             
            </div>
          </div>

          {/* <ReactSpeedometer
            maxSegmentLabels={0}
            segments={4}
            value={cibilScore}
            className="gradient-background"
            needleColor="#D8DEE9"
            textColor={"#fffff"}
          /> */}
        </div>
      </div>
    </div>
  );
};

export default Cibilresult;
