import React, { useState, useEffect } from "react";
import axios from "axios";
import { BASE_URL } from "../../../../env";
import "./Carvalidation.css";
import { useFormik } from "formik";
import RangeSlider from "react-bootstrap-range-slider";
import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

const CarValidation = () => {
  const [brands, setBrands] = useState([]);
  const [models, setModels] = useState([]);
  const [variants, setVariants] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState("");
  const [selectedModel, setSelectedModel] = useState("");
  const [selectedVariant, setSelectedVariant] = useState("");
  const [manufacturingYear, setManufacturingYear] = useState([]);
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedOwner, setSelectedOwner] = useState("");
  const [adjustedPrice, setAdjustedPrice] = useState(0);
  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [kilometersDriven, setKilometersDriven] = useState("");
  const [formresult, setFormResult] = useState("");
  const [resultModalOpen, setResultModalOpen] = useState(false);
  const [formData, setFormData] = useState({});
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [showModal, setShowModal] = useState(false);
  useEffect(() => {
    axios
      .get(`${BASE_URL}/brands`)
      .then((response) => {
        setBrands(response.data);

        // console.log(response.data)
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  // formik validation

  //  car model api
  const handleBrandChange = (event) => {
    const brandId = event.target.value;

    setSelectedBrand(parseInt(brandId));

    axios
      .get(`${BASE_URL}/models/${brandId}`)
      .then((response) => {
        setModels(response.data);
        setSelectedModel("");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleModelChange = (event) => {
    const modelId = event.target.value;
    setSelectedModel(parseInt(modelId));

    axios
      .get(`${BASE_URL}/variants/${modelId}`)
      .then((response) => {
        setVariants(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // mnufacturing year

  const ManufacturingYear = () => {
    const currentYear = new Date().getFullYear();
    const lastTenYears = [];

    for (let year = currentYear; year >= currentYear - 15; year--) {
      lastTenYears.push(year);
    }
    setManufacturingYear(lastTenYears);
  };

  useEffect(() => {
    ManufacturingYear();
  }, []);
  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };
  //  handle owner change

  const handleOwnerChange = (event) => {
    setSelectedOwner(event.target.value);
  };
  //  handle varint change
  const handleVariantChange = (event) => {
    const variantId = event.target.value;
    setSelectedVariant(parseInt(variantId));
  };

  // calculating valuation price

  const calculateAdjustedPrice = (baseprice, selectedYear, ownerType) => {
    const currentYear = new Date().getFullYear();
    let adjustedPrice = baseprice;
    for (let year = selectedYear; year <= currentYear; year++) {
      const yearsSinceManufacturing = year - selectedYear;
      let priceReductionPercentage;
      
      if (yearsSinceManufacturing <= 8) {
        if(adjustedPrice > 1000000){
          priceReductionPercentage = 0.10;
        }else{
          priceReductionPercentage = 0.05;
        }
      } else {
        priceReductionPercentage = 0.025;
      }

      adjustedPrice -= adjustedPrice * priceReductionPercentage;
    }
    if (ownerType === "first") {
      adjustedPrice -= adjustedPrice * 0.05;
    } else if (ownerType === "second") {
      adjustedPrice -= adjustedPrice * 0.1;
    } else if (ownerType === "third") {
      adjustedPrice -= adjustedPrice * 0.15;
    }

    return adjustedPrice;
  };
  const validatedPrice = async (e) => {
    e.preventDefault();

    // validating phonenumber

    if (!phoneNumber) {
      setPhoneNumberError("Phone number cannot be empty.");
      return;
    }
    if (!/^\d{10}$/.test(phoneNumber)) {
      setPhoneNumberError("Phone number must be a 10-digit number.");
      return;
    }

    if (phoneNumber === "9786543210" || phoneNumber === "1234567890") {
      setPhoneNumberError("Enter Valid phone Number");
      return;
    }
    // validating phonenumber

    if (selectedYear && selectedOwner && selectedVariant) {
      const selectedVariantObj = variants.find(
        (variant) => variant.variant_id === parseInt(selectedVariant)
      );

      if (selectedVariantObj) {
        const adjusted = calculateAdjustedPrice(
          selectedVariantObj.price,
          parseInt(selectedYear),
          selectedOwner
        );

        setAdjustedPrice(adjusted);

        const currentYear = new Date().getFullYear();
        const yearsSinceManufacturing = currentYear - parseInt(selectedYear);

        if (yearsSinceManufacturing < 3) {
          setMaxPrice(adjusted + 25000);
        } else if (yearsSinceManufacturing >= 3) {
          setMaxPrice(adjusted + 25000);
        }
        setMinPrice(adjusted);
      }

      console.log(selectedVariantObj.price);
    }
    console.log("clicked");

    try {
      const selectedBrandObj = brands.find(
        (brand) => brand.brand_id === selectedBrand
      );
      const selectedModelObj = models.find(
        (model) => model.model_id === selectedModel
      );
      const selectedVariantObj = variants.find(
        (variant) => variant.variant_id === selectedVariant
      );

      // console.log(
      //   " selectedBrandObj",
      //   selectedBrandObj.brand_name,
      //   selectedModelObj.model_name,
      //   selectedVariantObj.variant_name
      // );
      const updtedFormData = {
        kilometersDriven: kilometersDriven,
        selectedBrand: selectedBrandObj.brand_name,
        selectedModel: selectedModelObj.model_name,
        selectedVariant: selectedVariantObj.variant_name,
        selectedYear: selectedYear,
        phoneNumber: phoneNumber,
        selectedOwner: selectedOwner,
      };
      setFormData(updtedFormData);

      const response = await axios.post(
        `${BASE_URL}/submit-car-validation`,
        updtedFormData
      );

      console.log(response.data.message);
      setFormResult(response.data);

    } catch (err) {
      console.log("Error in FormSubmission", err);
    }
  };

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleHideModal = () => {
    setShowModal(false);
  };
  return (
    <>
      <div className="container-fluid carvalidation" id="booking">
        <div className="section-center">
          <div className="container">
            <div className="col-12">
              <div className="validation-form">
                <form>
                  <div className="form-floating">
                    <select
                      className="form-select"
                      id="floatingSelect"
                      aria-label="Floating label select example"
                      value={selectedYear}
                      onChange={handleYearChange}
                    >
                      <option selected value="">
                        select year
                      </option>
                      {manufacturingYear.map((year, i) => (
                        <option key={i} value={year}>
                          {year}
                        </option>
                      ))}
                    </select>
                    <label for="floatingSelect">Manufacturing year</label>
                  </div>

                  <div className="form-floating">
                    <select
                      className="form-select"
                      id="floatingSelect"
                      aria-label="Floating label select example"
                      value={selectedBrand}
                      onChange={handleBrandChange}
                    >
                      <option value="">Select your car Brand</option>
                      {brands.map((brand) => (
                        <option key={brand.brand_id} value={brand.brand_id}>
                          {brand.brand_name}
                        </option>
                      ))}
                    </select>
                    <label for="floatingSelect">Select Brand</label>
                  </div>
                  {/* car model */}

                  <div className="form-floating">
                    <select
                      className="form-select"
                      id="floatingSelect"
                      aria-label="Floating label select example"
                      value={selectedModel}
                      onChange={handleModelChange}
                    >
                      <option selected value="">
                        Select Model
                      </option>
                      {models.map((model) => (
                        <option key={model.model_id} value={model.model_id}>
                          {model.model_name}
                        </option>
                      ))}
                    </select>
                    <label for="floatingSelect">Select Model</label>
                  </div>
                  {/* car variant */}

                  <div className="form-floating">
                    <select
                      className="form-select"
                      id="floatingSelect"
                      aria-label="Floating label select example"
                      value={selectedVariant}
                      onChange={handleVariantChange}
                    >
                      <option selected value="">
                        Select variant
                      </option>
                      {variants.map((variant) => (
                        <option
                          key={variant.variant_id}
                          value={variant.variant_id}
                        >
                          {variant.variant_name}
                        </option>
                      ))}
                    </select>
                    <label for="floatingSelect">Select variant</label>
                  </div>

                  {/* year */}
                  <div className="form-floating">
                    <input
                      type="number"
                      className="form-control"
                      id="floatingInput"
                      placeholder="kilometeres driven"
                      onChange={(e) => setKilometersDriven(e.target.value)}
                    />
                    <label for="floatingInput">kilometeres Driven</label>
                  </div>
                  <div className="form-floating">
                    <input
                     
                      className="form-control"
                      id="floatingInput"
                      placeholder="mobile"
                  
                      type="number"
                      onChange={(e) => setPhoneNumber(e.target.value)}
                    />
                    <label for="floatingInput">Enter Your Number</label>
                  </div>
                  <div className="error-message text-danger">
                    {phoneNumberError}
                  </div>
                  {/* owner type */}

                  <div className="form-floating">
                    <div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          name="inlineRadioOptions"
                          id="inlineRadio1"
                          type="radio"
                          value="first"
                          checked={selectedOwner === "first"}
                          onChange={handleOwnerChange}
                        />
                        <label class="form-check-label" for="inlineRadio1">
                          First Owner
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          name="inlineRadioOptions"
                          id="inlineRadio2"
                          type="radio"
                          value="second"
                          checked={selectedOwner === "second"}
                          onChange={handleOwnerChange}
                        />
                        <label class="form-check-label" for="inlineRadio2">
                          second owner
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          name="inlineRadioOptions"
                          id="inlineRadio3"
                          type="radio"
                          value="third"
                          checked={selectedOwner === "third"}
                          onChange={handleOwnerChange}
                        />
                        <label class="form-check-label" for="inlineRadio3">
                          Third owner
                        </label>
                      </div>
                    </div>
                  </div>
                  <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={(e) => {
                      validatedPrice(e);
                      handleShowModal();
                    }}
                  >
                    Calculate Valuation
                  </button>
                  {adjustedPrice > 0 && (
                    <Modal show={showModal} centered>
                      <div className="modal-confirm">
                        <div className="modal-content">
                          <div className="modal-header">
                            <div className="icon-box">
                              <i className="fa fa-check" aria-hidden="true"></i>
                            </div>
                          </div>
                          <div className="modal-body">
                            <div className="slider">
                              {/* Your range slider JSX here */}
                              <div className=" col-12 d-flex justify-content-between range-slider">
                                <label htmlFor="min-price ">
                                  <br />
                                  <strong> {minPrice.toFixed(0)}</strong>
                                </label>
                                <label htmlFor="max-price" className="mx--5">
                                  <br />
                                  <strong>{maxPrice.toFixed(0)}</strong>
                                </label>
                              </div>
                              <div className="col-12 d-flex justify-content-center">
                                <RangeSlider
                                  id="min-price"
                                  value={minPrice}
                                  min={minPrice}
                                  step={10}
                                  tooltip="off"
                                  onChange={validatedPrice}
                                />

                                <RangeSlider
                                  id="max-price"
                                  value={maxPrice}
                                  min={0}
                                  max={maxPrice}
                                  step={10}
                                  tooltip="off"
                                  onChange={validatedPrice}
                                />
                              </div>
                              <div className="d-flex justify-content-between">
                                <p>Fair Condition</p>
                                <p>Good Condition</p>
                              </div>
                            </div>

                            <div className="car-validation-result">
                              <div className="result-row">
                                <span className="result-label">
                                  Selected Year
                                </span>
                                <span className="result-colon">:</span>
                                <span className="result-value">
                                  {selectedYear}
                                </span>
                              </div>
                              <div className="result-row">
                                <span className="result-label">
                                  Selected Brand
                                </span>
                                <span className="result-colon">:</span>
                                <span className="result-value">
                                  {
                                    brands.find(
                                      (brand) =>
                                        brand.brand_id === selectedBrand
                                    )?.brand_name
                                  }
                                </span>
                              </div>
                              <div className="result-row">
                                <span className="result-label">
                                  Selected Model
                                </span>
                                <span className="result-colon">:</span>
                                <span className="result-value">
                                  {
                                    models.find(
                                      (model) =>
                                        model.model_id === selectedModel
                                    )?.model_name
                                  }
                                </span>
                              </div>
                              <div className="result-row">
                                <span className="result-label">
                                  Selected variant
                                </span>
                                <span className="result-colon">:</span>
                                <span className="result-value">
                                  {
                                    variants.find(
                                      (variant) =>
                                        variant.variant_id === selectedVariant
                                    )?.variant_name
                                  }
                                </span>
                              </div>
                              <div className="result-row">
                                <span className="result-label">Owner Type</span>
                                <span className="result-colon">:</span>
                                <span className="result-value">
                                  {selectedOwner}
                                </span>
                              </div>
                              <div className="resultmessage">
                                {formresult.message}
                              </div>
                            </div>
                          </div>
                          <div className="modal-footer ">
                            <Button
                              variant="success"
                              className="btn-block"
                              onClick={handleHideModal}
                            >
                              OK
                            </Button>
                          </div>
                        </div>
                      </div>
                    </Modal>
                  )}


                  {/* <ResultModal
                    isOpen={resultModalOpen}
                    onClose={handleResultModalClose}
                    message={formresult.message}
                  /> */}
                </form>
              </div>
            </div>
            {/* form closing div */}
          </div>
        </div>
      </div>

    </>
  );
};

export default CarValidation;
