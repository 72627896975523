import React, { useState, useEffect } from "react";
import axios from "axios";
import { BASE_URL } from "../../../env";
import { DataGrid } from "@mui/x-data-grid";
import "../../components/datatable/datatable.css";
import NavigationHeader from "../../components/NavigationHeader";

const LoanCompleted = () => {
  const [Data, setData] = useState([]);

  useEffect(() => {
    fetchnotinterested();
  }, []);

  const fetchnotinterested = () => {
    const currentDate = new Date().toISOString().split("T")[0];

    axios
      .get(`${BASE_URL}/all/completedlist`)
      .then((res) => {
        console.log(res.data);
        const updatedData = res.data.map((row, index) => ({
          ...row,
          id: index + 1,
        }));
        setData(updatedData);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const notinterested = [
    { field: "id", headerName: "ID", Width: 200 },

    {
      field: "lastcallDate",
      headerName: "Last Call Date",
      width: 300,
      valueGetter: (params) => {
        const dateStr = params.row.lastTrialDate;
        const date = new Date(dateStr);
        return date.toISOString().split("T")[0];
      },
    },

    { field: "phoneNumber", headerName: "Phone Number", width: 300 },
    { field: "selectedBrand", headerName: "SelectedBrand", width: 300 },
    { field: "selectedModel", headerName: "SelectedModel", width: 300 },
    { field: "selectedVariant", headerName: "SelectedVariant", width: 300 },
  ];
  return (
    <div className="datatable">
      <NavigationHeader/>
      <div className="datatableTitle">
        <DataGrid
          className="datagrid"
          columns={notinterested}
          rows={Data}
          pageSize={9}
          rowsPerPageOptions={[9]}
        />
      </div>
    </div>
  );
};

export default LoanCompleted;
