import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import "../../components/datatable/datatable.css";
import axios from "axios";
import { BASE_URL } from "../../../env";
import FormDetail from "./modaldetail/FormDetail";
import CibilProfile from "./modaldetail/CibilProfile";
import NavigationHeader from "../NavigationHeader";

const AdminCibilCheck = () => {
  const [validationData, setValidationData] = useState([]);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isCibilDetailModalOpen, setIsCibilDetailModalOpen] = useState(false);
  const [viewDetailClicked, setViewDetailClicked] = useState(false);

  useEffect(() => {
    fetchValidationData();
  }, []);

  const fetchValidationData = () => {
    axios
      .get(`${BASE_URL}/loanapplication/cibilinformation`)
      .then((res) => {
        const updatedData = res.data.map((row, index) => ({
          ...row,
          serialNumber: index + 1,
        }));
        setValidationData(updatedData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleRowClick = (row) => {
    setSelectedRowId(row.id);
    setSelectedRow(row);
  };

  const openCibilDetailModal = () => {
    setIsCibilDetailModalOpen(true);
  };

  const closeCibilDetailModal = () => {
    setIsCibilDetailModalOpen(false);
  };

  const validationcolumns = [
    {
      field: "serialNumber",
      headerName: "S.NO",
      flex: 1,
      headerClassName: "custom-table-header",
    },
    {
      field: "appliedDate",
      headerName: "Applied Date",
      flex: 1,
      headerClassName: "custom-table-header",
      renderCell: (params) => {
        const date = new Date(params.row.appliedDate);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");

        const formattedDate = `${day}-${month}-${year}`;
        return <div>{formattedDate}</div>;
      },
    },
    {
      field: "first_name",
      headerName: "First Name",
      flex: 1,
      headerClassName: "custom-table-header",
    },
    {
      field: "last_name",
      headerName: "Last Name",
      flex: 1,
      headerClassName: "custom-table-header",
    },
    {
      field: "phone_number",
      headerName: "Phone Number",
      flex: 1,
      headerClassName: "custom-table-header",
    },
    {
      field: "cibilScore",
      headerName: "Cibil",
      flex: 1,
      headerClassName: "custom-table-header",
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      headerClassName: "custom-table-header",
      renderCell: (params) => (
        <div>
          <button
            className="btn btn-warning text-white mx-2"
            onClick={() => {
              openCibilDetailModal();
              setViewDetailClicked(true);
            }}
          >
            View Detail
          </button>
        </div>
      ),
    },
  ];

  return (
    <>
      <div>
        <div>
          <NavigationHeader />
          <div>
            <div>
              <DataGrid
                className="datagrid"
                columns={validationcolumns}
                rows={validationData}
                pageSize={9}
                rowsPerPageOptions={[9]}
                onRowClick={(params) => handleRowClick(params.row)}
                autoHeight
                sx={{
                  width: '100%',
                  '& .MuiDataGrid-cell': {
                    whiteSpace: 'normal',
                    wordWrap: 'break-word',
                  },
                }}
              />
            </div>
          </div>

          {viewDetailClicked && (
            <CibilProfile
              isOpen={isCibilDetailModalOpen}
              onRequestClose={closeCibilDetailModal}
              selectedRow={selectedRow}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default AdminCibilCheck;