import React, { useState } from "react";
import UserInformation from "./UserInformation";
import VehicleInformation from "./VehicleInformation";
import CibilProfile from "./CibilProfile";
import Cibilresult from "./Cibilresult";
const Responsive = () => {
  const [cibilScore, setCibilScore] = useState(null);
  const [page, setPage] = useState(1);
  const [loanAmount, setLoanAmount] = useState(0);
  const [maxPrice, setMaxprice] = useState("");
  const [selectedYear, setSelectedYear] = useState('');
  const [userId, setUserId] = useState("");
//  for showing result 
const[SelectedBrand,setSelectedBrand]=useState("")
const[SelectedModel,setSelectedModel]=useState(null);
const[SelectedVariant,setSelectedVariant]=useState(null);

  const nextPage = (year, newMaxPrice) => {
    setPage(page + 1);
    setSelectedYear(year);
    setMaxprice(newMaxPrice);
    
    // console.log(page);
  };
  const previousPage = () => {
    setPage(page - 1);

  };

  const handleVehicleInfo =(brand,model,variant)=>{
    setSelectedBrand(brand); 
    setSelectedModel(model); 
    setSelectedVariant(variant);
  }

// console.log("brand form parent now",SelectedBrand)

// console.log("brand form parent now",SelectedModel)

// console.log("brand form parent now",SelectedVariant)
  // cibil score change
  const handleLoanAmount = (loanAmount) => {
    setLoanAmount(loanAmount)
  }
  const handleCibilScoreChange = (score) => {
    setCibilScore(score)
  }

  const handleMaxPriceChange = (price) => {
    setMaxprice(price);
  };

  const handleUserIdChange = (newUserId) => {
    setUserId(newUserId);
  }
  // console.log("cibil score in parent component", cibilScore)
  return (
    <div className="mainpage">

   {page === 1 ? (
  <UserInformation onCibilScoreChange={handleCibilScoreChange} onNext={nextPage} onUserIdChange={handleUserIdChange} />
) : page === 2 ? (
  <VehicleInformation cibilScore={cibilScore} setMaxprice={setMaxprice} onPrevious={previousPage} onNext={nextPage} userId={userId} handleMaxPriceChange={handleMaxPriceChange}  handleVehicleInfo={handleVehicleInfo}/>
) : page === 3 ? (
  <CibilProfile selectedYear={selectedYear} cibilScore={cibilScore} maxPrice={maxPrice} onLoanAmountChange={handleLoanAmount} userId={userId} onPrevious={previousPage} onNext={nextPage} SelectedBrand={SelectedBrand} />
) : (
  <Cibilresult cibilScore={cibilScore} loanAmount={loanAmount} SelectedBrand={SelectedBrand} SelectedModel={SelectedModel} SelectedVariant={SelectedVariant} selectedYear={selectedYear}/>
)}



    </div>
  );
};

export default Responsive;
