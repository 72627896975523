import React, { useState, useEffect } from "react";
import axios from "axios";
import { BASE_URL } from "../../../env";
import { DataGrid } from "@mui/x-data-grid";
import Button from "react-bootstrap/Button";
import "../datatable/datatable.css";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NavigationHeader from "../NavigationHeader";

const LoanNotInterestedList = () => {
  const [Data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const [selectedDate, setSelectedDate] = useState("");
  const [comment, setComment] = useState("");
  const [selectedRowId, setSelectedRowId] = useState(null);

  const handleClose = () => {
    setShow(false);
    setSelectedRowId(null);
  };
  const handleShow = () => setShow(true);

  useEffect(() => {
    fetchnotinterested();
  }, []);

  const fetchnotinterested = () => {
    axios
      .get(`${BASE_URL}/loanapplication/all/notinterested/data`)
      .then((res) => {
       
        const updatedData = res.data.map((row, index) => ({
          ...row,
          serialNumber: index + 1,
        }));
        setData(updatedData);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  // handling selected row
  const handleRowSelection = (params) => {
    setSelectedRowId(params.id);
    handleShow();
  };

  // ADDING FOLLOWUPS

  const handleSaveChanges = () => {
    const dateFormatted = selectedDate.split("-");
    const day = dateFormatted[2];
    const month = dateFormatted[1];
    const year = dateFormatted[0];

    const formattedDateToSend = `${year}-${month}-${day}`;

    if (selectedRowId !== null) {
      const selectedRow = Data.find((row) => row.id === selectedRowId);
      const updatedRow = {
        id: selectedRowId,
        followupDate: formattedDateToSend,
        followComment: comment,
        ...selectedRow,
      };
      // new

      axios
        .post(`${BASE_URL}/notloanintersted/followup`, updatedRow)
        .then((res) => {
       
          toast.success("Added to Followup");

          axios
            .delete(`${BASE_URL}/remove/loannotinterested/${selectedRowId}`)
            .then(() => {
           
              
              fetchnotinterested();
            })
            .catch((deleteError) => {
              console.error("Error deleting Not Intersted data:", deleteError);
            });
          fetchnotinterested();
        })
        .catch((err) => {
          console.error(err);
        });
    }

    setSelectedDate("");
    setComment("");

    handleClose();
    fetchnotinterested();
  };

  const notinterested = [
    {
      field: "serialNumber",
      headerName: "S.NO",
      width: 150,
      headerClassName: "custom-table-header",
    },
    // { field: "id", headerName: "ID", Width: 200 },

    {
      field: "lastCallDate",
      headerName: "Last Call Date",
      width: 300,
      headerClassName: "custom-table-header",
      renderCell: (params) => {
        const date = new Date(params.row.lastCallDate);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");

        const formattedDate = `${day}-${month}-${year}`;

        return <div>{formattedDate}</div>;
      },
    },
    {
      field: "comment",
      headerName: "Comment",
      width: 300,
      headerClassName: "custom-table-header",
    },
    {
      field: "first_name",
      headerName: "First Name",
      width: 300,
      headerClassName: "custom-table-header",
    },
    {
      field: "last_name",
      headerName: "Last Name",
      width: 300,
      headerClassName: "custom-table-header",
    },
    {
      field: "phone_number",
      headerName: "Phone Number",
      width: 300,
      headerClassName: "custom-table-header",
    },
    {
      field: "cibilScore",
      headerName: "Cibil",
      width: 300,
      headerClassName: "custom-table-header",
    },
    {
      field: "loanAmount",
      headerName: "Loan Amount",
      width: 300,
      headerClassName: "custom-table-header",
    },

    {
      field: "addFollowupButton",
      headerName: "Add Followup",
      width: 150,
      headerClassName: "custom-table-header",
      renderCell: (params) => (
        <button
          className="btn btn-primary"
          onClick={() => handleRowSelection(params)}
        >
          Add Followup
        </button>
      ),
    },
  ];
  return (
    <div className="datatable">
      <NavigationHeader/>
      <div className="datatableTitle">
        <DataGrid
          className="datagrid"
          columns={notinterested}
          rows={Data}
          pageSize={9}
          rowsPerPageOptions={[9]}
        />
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>ADD FOLLOWUP</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Followup Date</Form.Label>
              <Form.Control
                type="date"
                placeholder="Next Followup Date"
                autoFocus
                value={selectedDate}
                onChange={(e) => setSelectedDate(e.target.value)}
              />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Comments</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSaveChanges}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default LoanNotInterestedList;
