import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import "../../components/datatable/datatable.css";
import axios from "axios";
import { BASE_URL } from "../../../env";
import FormDetail from "./modaldetail/FormDetail";
import CibilProfile from "./modaldetail/CibilProfile";
import NavigationHeader from "../NavigationHeader";
const LoanApplicationList = () => {
  const [validationData, setValidationData] = useState([]);
  const [showFormDetail, setShowFormDetail] = useState(false);
  const [followup, setFollowup] = useState({
    followdate: "",
    followComment: "",
  });

  const [notInterested, setnotInterested] = useState({
    lastcalldate: "",
    Comment: "",
  });
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [selectedRow,setSelectedRow]=useState(null);
  const [isFormDetailModalOpen, setIsFormDetailModalOpen] = useState(false);
  const [isCibilDetailModalOpen, setIsCibilDetailModalOpen] = useState(false);
  const [viewDetailClicked, setViewDetailClicked] = useState(false);
  useEffect(() => {
    fetchValidationData();
  }, []);

  const fetchValidationData = () => {
    axios
      .get(`${BASE_URL}/loanapplication/userinformation`)
      .then((res) => {
     
        const updatedData = res.data.map((row, index) => ({
          ...row,
          serialNumber: index + 1,
        }));
        setValidationData(updatedData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleFollowupForm = (event) => {
    const { name, value } = event.target;

    setFollowup((prevFollowup) => ({
      ...prevFollowup,
      [name]: value,
    }));
  };

  const handleFollowup = () => {
    if (!followup.followdate || !followup.followComment) {
      alert("Please enter a follow-up date and comments.");
      return;
    }

    const selectedRow = validationData.find((row) => row.id === selectedRowId);

    if (!selectedRow) {
      console.error("Selected row not found.");
      return;
    }

    const followupData = {
      ...selectedRow,
      followupDate: followup.followdate,
      followComment: followup.followComment,
    };

    axios
      .post(`${BASE_URL}/loanapplication/add/followup`, followupData)
      .then((res) => {
      
        setFollowup({ followdate: "", followComment: "" });
        fetchValidationData();
    
      })
      .catch((err) => {
        console.error(err);
      });
  };

  // handling not interested form

  const handlenotInterestedForm = (event) => {
    const { name, value } = event.target;

    setnotInterested((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // not interested
  const handleNotInterested = () => {
    if (!notInterested.lastcalldate || !notInterested.Comment) {
      alert("Please enter a  date and comments.");
      return;
    }

    const selectedRow = validationData.find((row) => row.id === selectedRowId);

    if (!selectedRow) {
      console.error("Selected row not found.");
      return;
    }

    const notInterestedData = {
      ...selectedRow,
      lastcalldate: notInterested.lastcalldate,
      Comment: notInterested.Comment,
    };

    axios
      .post(`${BASE_URL}/loanapplication/notInterested`, notInterestedData)
      .then((res) => {
     
        setnotInterested({ lastcalldate: "", Comment: "" });

        // alert("saved successfully.");

        axios
          .delete(`${BASE_URL}/car_validation/${selectedRowId}`)
          .then(() => {
            console.log("Car validation data deleted successfully.");
            fetchValidationData();
          })
          .catch((deleteError) => {
            console.error("Error deleting car validation data:", deleteError);
          });
        fetchValidationData();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleRowClick = (row) => {
    setSelectedRowId(row.id);
    setSelectedRow(row);
  };
  // handlecompleted

  const handleCompleted = () => {
    const selectedRow = validationData.find((row) => row.id === selectedRowId);

    if (!selectedRow) {
      console.error("Selected row not found.");
      return;
    }

    const completedData = {
      ...selectedRow,
    };

    axios
      .post(`${BASE_URL}/loan/application/completed`, completedData)
      .then((res) => {
   
        

        // alert("saved successfully.");

        // axios
        //   .delete(`${BASE_URL}/car_validation/${selectedRowId}`)
        //   .then(() => {
        //     console.log("Car validation data deleted successfully.");
        //     fetchValidationData();
        //   })
        //   .catch((deleteError) => {
        //     console.error("Error deleting car validation data:", deleteError);
        //   });
        fetchValidationData();
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const openFormDetailModal = () => {
    setIsFormDetailModalOpen(true);
  };

  const closeFormDetailModal = () => {
    setIsFormDetailModalOpen(false);
  }
  const openCibilDetailModal = () => {
    setIsCibilDetailModalOpen(true);
  };
  
  const closeCibilDetailModal = () => {
    setIsCibilDetailModalOpen(false);
  };
  
  const validationcolumns = [
    {
      field: "serialNumber",
      headerName: "S.NO",
      width: 150,
      headerClassName: "custom-table-header",
    },
    // { field: "id", headerName: "ID", Width: 100 },
    {
      field: "appliedDate",
      headerName: "Applied Date",
      width: 150,
      headerClassName: "custom-table-header",
      renderCell: (params) => {
        const date = new Date(params.row.appliedDate);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");

        const formattedDate = `${day}-${month}-${year}`;
        return <div>{formattedDate}</div>;
      },
    },
    {
      field: "first_name",
      headerName: "First Name",
      width: 150,
      headerClassName: "custom-table-header",
    },
    {
      field: "last_name",
      headerName: "Last Name",
      width: 150,
      headerClassName: "custom-table-header",
    },
    {
      field: "phone_number",
      headerName: "Phone Number",
      width: 200,
      headerClassName: "custom-table-header",
    },
    {
      field: "cibilScore",
      headerName: "Cibil",
      width: 100,
      headerClassName: "custom-table-header",
    },
    {
      field: "loanAmount",
      headerName: "Loan Amount",
      width: 200,
      headerClassName: "custom-table-header",
      renderCell: (params) => (
        <div>{parseFloat(params.value).toFixed(2)}</div>
      ),
    },

    {
      field: "actions",
      headerName: "Actions",
      width: 700,
      headerClassName: "custom-table-header",
      renderCell: (params) => (
        <div>
          <button className="btn btn-warning text-white mx-2"  onClick={() => {
    openCibilDetailModal();
    setViewDetailClicked(true);
  }}>
            View Detail
          </button>
          <button className="btn btn-secondary text-white mx-2"
 onClick={() => {
  setShowFormDetail(!showFormDetail);
  openFormDetailModal();
}}>
            Form  Detail
          </button>
          <button
            className="btn btn-success"
            data-bs-toggle="modal"
            data-bs-target="#followupmodal"
            onClick={() => handleRowClick(params.row)}
          >
            Follow Up
          </button>
          <button
            className="btn btn-danger mx-2"
            data-bs-toggle="modal"
            data-bs-target="#notinterestedmodal"
            onClick={() => handleRowClick(params.row)}
          >
            Not Interested
          </button>

          <button
            className="btn btn-primary mx-2"
            onClick={() => {
              handleRowClick(params.row);
              handleCompleted();
            }}
          >
            Completed
          </button>
        </div>
      ),
    },
  ];

  return (
    <>
     <div className="d-flex">
  
     <div>
     <NavigationHeader /> 
     <div className="datatable" style={{width:"80%"}}>
        <div className="datatableTitle">
          <DataGrid
            className="datagrid"
            columns={validationcolumns}
            rows={validationData}
            pageSize={9}
            rowsPerPageOptions={[9]}
            onRowClick={(params) => handleRowClick(params.row)}
          />
        </div>
      </div>
      {/* Modal for Follow Up */}
      <div
        className="modal fade"
        id="followupmodal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Add a Followup
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="form-floating mb-3">
                <input
                  type="date"
                  className="form-control"
                  id="floatingInput"
                  name="followdate"
                  value={followup.followdate}
                  onChange={handleFollowupForm}
                />
                <label htmlFor="floatingInput">Follow up Date</label>
              </div>
              <div className="form-floating mb-3">
                <textarea
                  className="form-control"
                  placeholder="Leave a comment here"
                  id="floatingTextarea"
                  name="followComment"
                  value={followup.followComment}
                  onChange={handleFollowupForm}
                />
                <label htmlFor="floatingTextarea">Comments</label>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
                onClick={handleFollowup}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* Modal for Not Interested */}
      <div
        className="modal fade"
        id="notinterestedmodal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Not Interested
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="form-floating mb-3">
                <input
                  type="date"
                  className="form-control"
                  id="floatingInput"
                  name="lastcalldate"
                  value={notInterested.lastcalldate}
                  onChange={handlenotInterestedForm}
                />
                <label htmlFor="floatingInput">Last Call Date</label>
              </div>
              <div className="form-floating mb-3">
                <textarea
                  className="form-control"
                  placeholder="Leave a comment here"
                  id="floatingTextarea"
                  name="Comment"
                  value={notInterested.Comment}
                  onChange={handlenotInterestedForm}
                />
                <label htmlFor="floatingTextarea">Comments</label>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
                onClick={handleNotInterested}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
      {showFormDetail && <FormDetail selectedRow={selectedRow}
        isOpen={isFormDetailModalOpen}
        onRequestClose={closeFormDetailModal}
        />}
{viewDetailClicked && (
  <CibilProfile
    isOpen={isCibilDetailModalOpen}
    onRequestClose={closeCibilDetailModal}
    selectedRow={selectedRow}
  />
)}

     </div>
     </div>
    
      
    </>
  );
};

export default LoanApplicationList;
