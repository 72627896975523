import React,{useState} from "react";
import "./adminform.css";
import { useNavigate } from "react-router-dom";
import { adminValidation  } from "./AdminValidation";
import axios from "axios";
import { BASE_URL } from "../../../env";
function AdminRegister() {
const [inputValues,setInputValues]=useState({
  firstName:"",
  lastName:"",
  mobile:"",
  password:""

})
const[error,setError]=useState({})
 const navigate=useNavigate()
const handleInput =(event)=>{
  setInputValues(prev=>({...prev,[event.target.name]:[event.target.value]}))
}
const handleSubmit =(event)=>{
  event.preventDefault();
  const validationErrors = adminValidation (inputValues);
  setError(validationErrors);
// setError(registerValidation(inputValues))
// console.log(inputValues)
if(Object.keys(validationErrors).length ===0){
  axios.post(`${BASE_URL}/adminsignup`,inputValues)
  .then(res=>{
    console.log(res)
    navigate("/adminlogin")
  })
  .catch(err=>console.log(err))
}

}
  return (
    <section >
      <div className="container-fluid admin-signup">
        <div className="col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4 text-center">
          <div className="logo-register">{/* <img></img> */}</div>
          <form className="rounded bg-white shadow py-5 px-4" onSubmit={handleSubmit}>
            <h3 className="text-dark fw-bolder fs-4 mb-2">Register</h3>
            <div className="fw-normal text-muted mb-4">
              {" "}
              Already have an account 
              <a href="#" className="text-primary fw-bold text-decoration-none" onClick={()=>navigate("/adminlogin")}>
              <strong> Sign In</strong> 
              </a>
            </div>
            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control"
                id="firstname"
                placeholder="Enter Your First Name"
                name="firstName"
                onChange={handleInput}
               
              />
              <label htmlFor="firstname">First Name</label>
              {error.firstName && <span className="error-admin">{error.firstName}</span>}
            </div>
            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control"
                id="lastname"
                placeholder="Enter Your Last Name"
                name="lastName"
                onChange={handleInput}
              />
              <label htmlFor="lastname">Last Name</label>
              {error.lastName&&<span className="error-admin">{error.lastName}</span>}
            </div>

            <div className="form-floating mb-3">
              <input
                type="number"
                className="form-control"
                id="mobilenumber"
                placeholder="Enter Your Mobile Number"
                name="mobile"
                onChange={handleInput}
              />
              <label htmlFor="mobilenumber">Mobile Number</label>
              {error.mobile && <span className="error-admin">{error.mobile}</span>}
            </div>

            <div className="form-floating mb-3">
              <input
                type="password"
                className="form-control"
                id="password"
                placeholder="Enter Your Password"
                name="password"
                onChange={handleInput}
              />
              <label htmlFor="mobilenumber">Password</label>
              {error.password && <span className="error-admin">{error.password}</span>}
            </div>
       
            <button
              type="submit"
              className="btn btn-primary submit_btn w-100 my-4"
            >
              Register
            </button>
          </form>
        </div>
      </div>
    </section>
  );
}

export default  AdminRegister;
