import React from 'react'
import moment from 'moment';

const FormDetail = ({selectedRow,isOpen,onRequestClose}) => {

  const formDetailRowStyle = {
    display: 'grid',
    gridTemplateColumns: '50% auto 50%',
    alignItems: 'center',
    marginBottom: '10px'
  };

  const formLabelStyle = {
    textAlign: 'left',
    paddingRight: '10px'
  };

  const formColonStyle = {
    textAlign: 'center'
  };

  const formValueStyle = {
    textAlign: 'left',
    paddingLeft: '10px'
  };

  const formatNumber = (number, decimalPlaces = 0) => {
    if (number === null || number === undefined || number === '') return 'N/A';
    return parseFloat(number).toFixed(decimalPlaces);
  };

    return (
        <div className={`modal ${isOpen ? 'show' : ''}`} tabIndex="-1" style={{ display: isOpen ? 'block' : 'none' }}>
          <div className="modal-dialog modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title credit-heading">Form Detail</h5>
                <button type="button" className="btn-close" onClick={onRequestClose} aria-label="Close"></button>
              </div>
              <div className="modal-body">
              {selectedRow && (
              <div>
                {[
                  { label: 'Name', value: `${selectedRow.first_name} ${selectedRow.last_name}` },
                  { label: 'Phone Number', value: selectedRow.phone_number },
                  { label: 'Applied Date', value: moment(selectedRow.appliedDate).format('DD/MM/YYYY') },

                  { label: 'CIBIL Score', value: selectedRow.cibilScore },
                  { label: 'PAN Number', value: selectedRow.pan_number },
                  { label: 'Loan Amount', value: formatNumber(selectedRow.loanAmount,0) },
                  { label: 'Selected Year', value: selectedRow.selectedYear },
                  { label: 'Selected Brand', value: selectedRow.selectedBrand },
                  { label: 'Selected Model', value: selectedRow.selectedModel },
                  { label: 'Selected Variant', value: selectedRow.selectedVariant },
                  { label: 'Kilometers Driven', value: selectedRow.kilometersDriven },
                  { label: 'Owner Type', value: selectedRow.ownerType },
                  { label: 'Maximum Price', value: formatNumber(selectedRow.maxprice, 0) },
                  // { label: 'Minimum Price', value: formatNumber(selectedRow.minprice) },
                  { label: 'Profile Type', value: selectedRow.profile_type },
                  { label: 'Aadhar Number', value: selectedRow.aadhar_number },
                  { label: 'Monthly Income', value: selectedRow.monthly_income },
                  { label: 'Existing EMI', value: selectedRow.existing_emi },
                  { label: 'Self Employment Type', value: selectedRow.self_employed_type },
                  { label: 'Acres', value: selectedRow.acres },
                  { label: 'Existing Loan Amount', value: selectedRow.existing_loan_amount },
                  { label: 'Tenure Completed', value: selectedRow.tenure_completed },
                  { label: 'Residence Type', value: selectedRow.residence_type },
                  { label: 'Own Type', value: selectedRow.own_type },
                  { label: 'Repayment EMI', value: selectedRow.repayment_emi },
                  { label: 'Place Of Stay', value: selectedRow.place_of_stay }
                ].map(({ label, value }) => (
                  <div key={label} style={formDetailRowStyle}>
                    <span style={formLabelStyle}>{label}</span>
                    <span style={formColonStyle}>:</span>
                    <span style={formValueStyle}>{value}</span>
                  </div>
                ))}
              </div>
            )}
              
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" onClick={onRequestClose}>
                  Close
                </button>
                {/* You can add a "Save changes" button or any other actions here */}
              </div>
            </div>
          </div>
        </div>
      );
}

export default FormDetail