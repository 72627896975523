import React from 'react';
import "./partners.css";
import { channels } from '../../dummydatas/channeldata';


export default function Partners(){
    return(
        <div className='container col-12'>
           <div className='row col-md-12 col-sm-3  partner-container  '>
         <h4 className='section-header'> Bank and NBFC Partners</h4>
             {channels.map((data,i)=>{
               return(
                  <div key={i} className='branch col-sm-4 col-md-12 col-xl-12 mx-auto'>
                 <img src=  {data.image} className='img-fluid  '/>
                     </div>
               )
             })}
           </div>
      </div>
    )

}