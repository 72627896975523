import React, { useState, useEffect } from "react";
import { useNavigate,useLocation } from "react-router-dom";
import "./Dealerform.css";
import { dealerloginValidation } from "./dealerValidation";
import {toast,ToastContainer}from "react-toastify";
import axios from "axios";
import { BASE_URL } from "../../env";
import { useAuth } from "../../components/AuthContext/AuthorizationContext";
function DealerLogin() {
  const navigate = useNavigate();
  const location=useLocation();
  const { login } = useAuth();
  const[dealerDetail,setDealerDetail]=useState("")
  const [inputValues, setInputValues] = useState({
    mobile: "",
    password: "",
  });
  const [error, setError] = useState({});

  const handleInput = (event) => {
    setInputValues((prev) => ({
      ...prev,
      [event.target.name]: [event.target.value],
    }));
  };

  const handleLogin = (responseData) => {
    const { token, firstName, lastName, mobile } = responseData;
    login(token);
    localStorage.setItem("dealerToken", token);
    localStorage.setItem("dealerFirstName", firstName);
    localStorage.setItem("dealerLastName", lastName);
    localStorage.setItem("dealerMobile", mobile);
    navigate("/loanform/user/applyloan");
  
  };
  const handleSubmit = (event) => {
    event.preventDefault();
 
   
    const loginError = dealerloginValidation(inputValues);
    setError(loginError);

    if (Object.keys(loginError).length === 0) {
      axios
        .post(`${BASE_URL}/dealerlogin`, inputValues)
        .then((res) => {
          const { firstName, lastName, mobile, token } = res.data;

         
          console.log("First Name:", firstName);
          console.log("Last Name:", lastName);
          console.log("Mobile:", mobile);
        

          if (res.data.message === "User Logined Successfully") {
           console.log(res.data);
           toast.success("Logined Successfully")
            handleLogin(res.data);
            setDealerDetail(res.data);
          
         
          } else {
           
            alert("No user existed");
          }
        })
        .catch((err) =>{
          console.log(err);
          toast.error("Login Failed")
        });

    }
  
  };
 
  return (
    <section className="wrapper-dealerlogin">
      <div className=" login-dealer">
    
      
        <div className="formlogin-dealer">
        <div className="back-button">
          <i className="fas fa-chevron-left "><span  onClick={() => navigate("/")}> Back</span></i>
          </div>
          <form onSubmit={handleSubmit} className="login_dealer">
         
            <div className="login_field">
            <i className="login_icon fas fa-user"></i>
              <input
                className="login_input"
                name="mobile"
                onChange={handleInput}
                placeholder="Phone number"
              />

              {error.password && (
                <span className="error-dealer">{error.password}</span>
              )}
            </div>
            <div className="login_field">
              <i class="login_icon fas fa-lock"></i>
              <input
                type="password"
                className="login_input"
                placeholder="Password"
                name="password"
                onChange={handleInput}
              />

              {error.password && (
                <span className="error-dealer">{error.password}</span>
              )}
            </div>

            <button type="submit" className="login_submit">
              <span className="button-text"> Log In Now </span>
              <i className="button_icon fas fa-chevron-right"></i>
            </button>
            <button  className="register-btn"  onClick={() => navigate("/dealersignup")}>
              <span className="button-text">Create an Account</span>
              <i className="button_icon_create fas fa-chevron-right"></i>
            </button>
          
          </form>
        </div>
        <div className="screen__background">
          <span class="screen__background__shape screen__background__shape4"></span>
          <span class="screen__background__shape screen__background__shape3"></span>
          <span class="screen__background__shape screen__background__shape2"></span>
          <span class="screen__background__shape screen__background__shape1"></span>
        </div>
      </div>
      <ToastContainer position="top-right" autoClose={3000} hideProgressBar={false}
      newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover/>
    </section>
  );
}

export default DealerLogin;
