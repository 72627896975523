import React from "react";
import "./Navbar.css";
import { NavLink, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { useAuth } from "../AuthContext/AuthorizationContext";
import logo from "../../asserts/logo/logo.png"
const Navbar = () => {
  const { token, logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate("/");
  };

  return (
    <>
      <nav className="navbar navbar-expand-md  fixed-top">
        <div className="container-fluid">
          <span className="nav-brandname mx--2" >
            {/* AgalyaFin */}
            <img src={logo} alt="logo" className="logo" />
          </span>
          <button
            className="navbar-toggler  border-0 shadow-none"
            data-bs-toggle="collapse"
            data-bs-target="#nav"
            aria-controls="nav"
            aria-label="Expand Navigation"
          >
            <div className="navbar-toggler-icon"></div>
          </button>

          <div className="collapse navbar-collapse " id="nav">
            <ul className="navbar-nav ms-auto">

              <li class="nav-item">
                <a class="nav-link" href="#HeroSession">Home</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#CalculatorSession">Emi Calculator</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#ServiceSession">Services</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#ContactSession">Contact</a>
              </li>

              <li className="nav-item mx-auto">
                {token ? (
                  <button
                    className="nav-link dealer-button"
                    onClick={handleLogout}
                  >
                    Logout
                  </button>
                ) : (
                  <Link className="dealer-button" to="/dealerlogin" style={{ color: "#26284e;" }}>
                    DealerLogin
                  </Link>
                )}
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
