import React,{useEffect} from "react";
import "./form/applyform/loanform.css";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";

const Privacy = () => {

useEffect(()=>{
window.scrollTo(0,0)
});


  return (
    <div style={{}}>
      <div className="privacybannerimg">
        <img
          src={require("./.././asserts/formsimg/privacypolicy.jpg")}
          alt="privacy"
          className="img-fluid"
        />
        <h1 className="privacyheading">Privacy Policy</h1>
      </div>
      <div className="mt-5 px-2">
        <div className="d-flex justify-content-center">
          <p className="privacypara">
            At AgalyaFinserv, we are committed to protecting the privacy of our
            customers and users. This Privacy Policy outlines how we collect,
            use, and safeguard your personal information when you interact with
            our services.
          </p>
        </div>

        <p className="privacyinfoheading">Information We Collect</p>
        <p className="privacyinfopara">
          We may collect the following types of personal information :
        </p>
        <div className="row d-flex justify-content-center">
          <ol className="col-lg-5 d-flex-column ">
            <div className="d-flex my-3  ">
              <ArrowRightAltIcon />
              <li className="privacylistitem">
                Contact information (such as name, address, email address, phone
                number)
              </li>
            </div>
            <div className="d-flex my-3  ">
              <ArrowRightAltIcon />
              <li className="privacylistitem">
                Financial information (such as income, credit history, loan
                information)
              </li>
            </div>

            <div className="d-flex my-3  ">
              <ArrowRightAltIcon />
              <li className="privacylistitem">
                Vehicle information (such as make, model, year, VIN)
              </li>
            </div>
            <div className="d-flex my-3  ">
              <ArrowRightAltIcon />
              <li className="privacylistitem">
                Employment information (such as employer, job title)
              </li>
            </div>
          </ol>
        </div>

        <p className="privacyinfopara">
          We collect this information when you apply for a used car loan or use
          our website or mobile app
        </p>

        <p  className="privacyinfoheading">How We Use Your Information</p>
        <p className="privacyinfopara">
          We use your personal information for the following purposes :
        </p>
        <div className="row d-flex justify-content-center">
          <ol className="col-lg-5 d-flex-column ">
            <div className="d-flex my-3  ">
              <ArrowRightAltIcon />
              <li className="privacylistitem">
                Processing loan applications and providing loan services
              </li>
            </div>
            <div className="d-flex my-3  ">
              <ArrowRightAltIcon />
              <li className="privacylistitem">
                Communicating with you about your loan application or account
              </li>
            </div>

            <div className="d-flex my-3  ">
              <ArrowRightAltIcon />
              <li className="privacylistitem">
                Personalizing and improving our services
              </li>
            </div>
            <div className="d-flex my-3  ">
              <ArrowRightAltIcon />
              <li className="privacylistitem">
                Complying with legal and regulatory requirements
              </li>
            </div>
          </ol>
        </div>

        {/* info sharing */}

        <p  className="privacyinfoheading">Information Sharing</p>

        <p className="privacyinfopara">
          We may share your personal information with third parties in the
          following circumstances:
        </p>
        <div className="row d-flex justify-content-center">
          <ol className="col-lg-5 d-flex-column ">
            <div className="d-flex my-3  ">
              <ArrowRightAltIcon />
              <li className="privacylistitem">With your consent</li>
            </div>
            <div className="d-flex my-3  ">
              <ArrowRightAltIcon />
              <li className="privacylistitem">
                To service providers who help us process loans or manage our
                business
              </li>
            </div>

            <div className="d-flex my-3  ">
              <ArrowRightAltIcon />
              <li className="privacylistitem">
                To comply with legal obligations or respond to legal requests
              </li>
            </div>
            <div className="d-flex my-3  ">
              <ArrowRightAltIcon />
              <li className="privacylistitem">
                In connection with a merger, acquisition, or sale of assets
              </li>
            </div>
          </ol>
        </div>
        {/* data security */}

        <p  className="privacyinfoheading">Data Security</p>

        <p className="privacyinfopara">
          We take appropriate measures to protect your personal information from
          unauthorized access, alteration, disclosure, or destruction
        </p>
        <div className="row d-flex justify-content-center">
          <ol className="col-lg-5 d-flex-column ">
            <div className="d-flex my-3  ">
              <ArrowRightAltIcon />
              <li className="privacylistitem">Sharing Your Information :</li>
            </div>
            <div className="d-flex my-3  ">
              <ArrowRightAltIcon />
              <li className="privacylistitem">
                Third-Party Service Providers: We may share your information
                with third-party service providers who assist us in processing
                loans, conducting credit checks, or providing other services on
                our behalf.
              </li>
            </div>

            <div className="d-flex my-3  ">
              <ArrowRightAltIcon />
              <li className="privacylistitem">
                Legal Requirements: We may disclose your information when
                required by law, legal process, or to protect our rights,
                property, or safety
              </li>
            </div>
          </ol>
        </div>

        {/* choices */}

        <p  className="privacyinfoheading">Your Choices</p>

        <p className="privacyinfopara">
          You have the following choices regarding your personal information :
        </p>
        <div className="row d-flex justify-content-center">
          <ol className="col-lg-5 d-flex-column ">
            <div className="d-flex my-3  ">
              <ArrowRightAltIcon />
              <li className="privacylistitem">
                You can update or correct your information by contacting us
              </li>
            </div>
            <div className="d-flex my-3  ">
              <ArrowRightAltIcon />
              <li className="privacylistitem">
                You can opt out of receiving marketing communications from us
              </li>
            </div>

            <div className="d-flex my-3  ">
              <ArrowRightAltIcon />
              <li className="privacylistitem">
                You can request deletion of your account and personal
                information
              </li>
            </div>
          </ol>
        </div>

        {/* policy */}

        <p className="privacyinfopara">Changes to This Policy :</p>
        <div className="row d-flex justify-content-center">
          <ol className="col-lg-5 d-flex-column ">
            <div className="d-flex my-3  ">
              <ArrowRightAltIcon />
              <li className="privacylistitem">
                We may update this Privacy Policy from time to time. The updated
                policy will be posted on our website.
              </li>
            </div>
          </ol>
        </div>
        {/* contact us */}
        <p  className="privacyinfoheading">Contact Us</p>
        <div className="row d-flex justify-content-center">
          <ol className="col-lg-5 d-flex-column ">
            <div className="d-flex my-3  ">
              <ArrowRightAltIcon />
              <li className="privacylistitem">
                If you have any questions or concerns about this Privacy Policy
                or our data practices, please contact us at{" "}
                <a href="mailto:AgalyaFinserv.com">AgalyaFinserv.com</a>.
              </li>
            </div>
          </ol>
        </div>
      </div>
    </div>
  );
};

export default Privacy;
