import React from "react";
import AdminSidebar from "../components/sidebar/AdminSidebar";
import Widget from "../components/widget/Widget";
import Tabledata from "../components/table/Table";
import "../pages/admin.css";
const Admin = () => {
  return (
    <>
      <div className="home">
        <AdminSidebar />
        <div className="homeContainer">
          {/* <AdminNavbar/> */}
          <div className="widgets">
            <Widget type="dealer" />
            <Widget type="admincibilvalidation" />
            <Widget type="validation" />
            <Widget type="registration" />
            <Widget type="registereddealers" />
            <Widget type="carmodels" />
          </div>

          <div className="listContainer">
            <div className="listTitle">Latest Applications</div>
            <Tabledata />
          </div>
        </div>
      </div>
    </>
  );
};
export default Admin;
