import React from "react";
import "./widget.css";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LocalCarWashIcon from '@mui/icons-material/LocalCarWash';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import { useNavigate } from "react-router-dom";

const Widget = ({ type }) => {

  const navigate =useNavigate();
 let data ={
  title:"",
  link:"",
  icon:null
 }

  switch (type) {
  
    case "dealer":
  data = {
        title: "Loan Applications",
        link: "See all ",
      
        icon: (
          <PersonOutlinedIcon
            className="icon"
            style={{
              color: "crimson",
              backgroundColor:"white",
              
            }}
          />
        ),
      };
      break;
      case "admincibilvalidation":
        data = {
              title: "Admin Cibil List",
              link: "See all ",
            
              icon: (
                <PersonOutlinedIcon
                  className="icon"
                  style={{
                    color: "crimson",
                    backgroundColor:"white",
                    
                  }}
                />
              ),
            };
            break;

    case "validation":
      data = {
        title: "Car Validation ",
        isMoney: true,
        link: "See all",
        icon: (
          <LocalCarWashIcon
            className="icon"
            style={{    backgroundColor:"white", color: "green" }}
          />
        ),
      };
      break;
    case "registration":
      data = {
        title: "Dealer Registration",
        isMoney: true,
        link: "Signup",
       
        icon: (
          < HowToRegIcon
            className="icon"
            style={{
              backgroundColor:"white",
              color: "purple",
            }}
           
          />
        ),
      };
      break;
    case "registereddealers":
      data={
        title:"Dealers",
        link:"see all",
        icon: (
          < HowToRegIcon
            className="icon"
            style={{
              backgroundColor:"white",
              color: "purple",
            }}
           
          />
        ),
      };
      break;
      case "carmodels":
        data={
          title:"Car Models",
          link:"see all",
          icon:(
            <LocalCarWashIcon
            className="icon"
            style={{    backgroundColor:"white", color: "green" }}
          />
          ),
        };
        break;

    default:
    
  }
  const handleWidgetClick = () => {
    switch (type) {
      case 'dealer':
        navigate('/admin/loan/applicationlist');
        break;
        case 'admincibilvalidation':
          navigate('/admin/loan/cibil');
          break;
      case 'validation':
        navigate('/admincarvalidationlist');
        break;
      case 'registration':
        navigate('/dealersignup');
        break;
      case "registereddealers":
        navigate("/admindealerregistered");
        break;
      case "carmodels":
        navigate("/admincarvalidationmodel");
        break;
      default:
        
    }
  };
  return (
    <div className="widget" onClick={handleWidgetClick}>
      <div className="left">
        <span className="title" style={{ cursor: 'pointer' }}>{data.title}</span>
        <span className="counter">
          {/* {data.isMoney && "$"} {amount} */}
        </span>
        <span className="link"  style={{ cursor: 'pointer' }}>{data.link}</span>
      </div>
      <div className="right">
        <div className="percentage positive">
          <KeyboardArrowUpIcon className="arrow" />
        {/*   {diff} % */}
        </div>
        {data.icon}
      </div>
    </div>
  );
};

export default Widget;
